import {
  RejectTaskSuccessAction,
  RejectTaskFailureAction,
  RejectTaskRequestAction,
  TasksState,
} from "../../types";

export const rejectTaskRequestCase = (
  state: TasksState,
  _: RejectTaskRequestAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
  },
});

export const rejectTaskSuccessCase = (
  state: TasksState,
  action: RejectTaskSuccessAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    data: action.payload,
  },
});

export const rejectTaskFailureCase = (
  state: TasksState,
  action: RejectTaskFailureAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    errors: action.payload.errors,
  },
});
