import { createAction } from "typesafe-actions";
import {
  GetUserInfoSuccessResponse,
  GetUserInfoFailureResponse,
} from "../../types";

export const getUserInfo = createAction("trigger/GET_USER_INFO")();
export const getUserInfoRequest = createAction(
  "action/GET_USER_INFO_REQUEST"
)();
export const getUserInfoSuccess = createAction("action/GET_USER_INFO_SUCCESS")<
  GetUserInfoSuccessResponse['data']
>();
export const getUserInfoFailure = createAction("action/GET_USER_INFO_FAILURE")<
  GetUserInfoFailureResponse
>();
