import { SagaIterator } from "redux-saga";
import { put, call, race, take } from "redux-saga/effects";
import { verifyTokenApi } from "../../api";
import {
  verifyTokenRequest,
  verifyTokenSuccess,
  verifyTokenFailure,
  signOut,
  getUserInfo,
  refreshTokenSuccess,
  refreshTokenFailure,
  refreshToken,
} from "../../actions";

export function* verifyTokenSaga(): SagaIterator {
  yield put(verifyTokenRequest());
  try {
    const { errors, payload } = yield call(verifyTokenApi);
    if (errors) {
      yield put(verifyTokenFailure());
      yield put(signOut());
      return;
    }
    yield put(refreshToken(payload));
    const { failed } = yield race({
      refreshed: take(refreshTokenSuccess),
      failed: take(refreshTokenFailure),
    });
    if (failed) {
      yield put(verifyTokenFailure());
      yield put(signOut());
      return;
    }
  } catch (e) {
    yield put(verifyTokenFailure());
    yield put(signOut());
    return;
  }

  yield put(getUserInfo());
  yield put(verifyTokenSuccess());
}
