import React, { useMemo, useState, useEffect } from "react";
import { IonPage, IonCard, IonContent } from "@ionic/react";
import { Link } from "react-router-dom";
import { SignUpForm } from "../../components/organisms";
import { useAuthentication } from "../../hooks";
import { Logo, Flex, Spacer } from "../../components/atoms";
import { Box } from "@material-ui/core";

const SignUpScreen: React.FC = () => {
  const { user } = useAuthentication();
  const [showThankYou, setShowThankYou] = useState<boolean>(false);

  useEffect(() => {
    // this means we registered successfully
    if (user) {
      setShowThankYou(true);
    }
  }, [user]);

  const ThankYouMessage = useMemo(
    () => (
      <IonCard style={{ padding: 30, textAlign: "center" }}>
        <div>
          <h2>Thank you for registering with us</h2>
          <p>
            You should receive an email in minutes with a <b>magic link</b>
            <br /> to set your password, activate your account and pluck your
            first Mango 🥭
          </p>
        </div>
      </IonCard>
    ),
    []
  );

  return (
    <IonPage>
      <IonContent>
        <Box
          style={{ maxWidth: "90%", width: 600, margin: "0 auto" }}
          textAlign="center"
          padding={2}
        >
          <Logo width="300px" />
          <h2>SignUp</h2>
          {showThankYou ? ThankYouMessage : <SignUpForm />}
          <Flex height="50px">
            <Link to="./login">Already registered?</Link>
            <Spacer width="10px" />
            <Link to="./recover-password">Forgot password</Link>
          </Flex>
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(SignUpScreen);
