import { fetch } from "../../utils/fetch";
import { AUTH_URL } from "../../constants";
import {
  UpdateProfilePayload,
  UpdateProfileSuccessResponse,
  UpdateProfileFailureResponse,
} from "../../types";

type Response = UpdateProfileSuccessResponse | UpdateProfileFailureResponse;

export const updateProfileApi = async (
  payload: UpdateProfilePayload
): Promise<Response> => {
  const { id, ...rest } = payload;
  try {
    const response: Response = await fetch.put({
      url: `${AUTH_URL}users/${payload.id}/update`,
      params: JSON.stringify(rest),
    });
    return response;
  } catch (e) {
    return e;
  }
};
