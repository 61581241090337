import { fetch } from "../../utils/fetch";
import { MMS_URL } from "../../constants";
import {
  GetMilestonePayload,
  GetMilestoneSuccessResponse,
  GetMilestoneFailureResponse,
} from "../../types";

type Response = GetMilestoneSuccessResponse | GetMilestoneFailureResponse;

export const getMilestoneApi = async (
  payload: GetMilestonePayload
): Promise<Response> => {
  try {
    const response: Response = await fetch.get({
      url: `${MMS_URL}milestones/${payload.milestone_id}`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
