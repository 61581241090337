import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import {
  updateAllNotificationsReadRequest,
  updateAllNotificationsReadFailure,
  getNotifications,
} from "../../actions";
import { updateAllNotificationsReadApi } from "../../api";

export function* updateAllNotificationsReadSaga(): SagaIterator {
  yield put(updateAllNotificationsReadRequest());
  try {
    const { errors, status } = yield call(updateAllNotificationsReadApi);
    if (errors) {
      yield put(updateAllNotificationsReadFailure({ errors, status }));
      return;
    }

    yield put(getNotifications());
  } catch (e) {
    yield put(
      updateAllNotificationsReadFailure({ errors: e || "Generic error" })
    );
  }
}
