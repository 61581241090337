import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { call, put, select } from "redux-saga/effects";
import { rejectTasksApi } from "../../api/tasks/rejectTask";
import {
  rejectTask,
  rejectTaskRequest,
  rejectTaskSuccess,
  rejectTaskFailure,
} from "../../actions";
import { getTasksList } from "../../selectors";
import { Task } from "../../types";

export function* rejectTaskSaga(
  action: ActionType<typeof rejectTask>
): SagaIterator {
  yield put(rejectTaskRequest());
  try {
    const params = action.payload;
    const { errors, status, data } = yield call(rejectTasksApi, params);
    if (errors || !data || !data.id) {
      yield put(rejectTaskFailure({ errors, status }));
      return;
    }

    const tasks = yield select(getTasksList);
    const newTasks = tasks.filter((task: Task) => task.id !== data.id);
    yield put(rejectTaskSuccess([...newTasks, data]));
  } catch (e) {
    yield put(rejectTaskFailure({ errors: e || "Generic error" }));
  }
}
