import {
  AuthState,
  SignInSuccessAction,
  SignInFailureAction,
  SignInRequestAction,
} from "../../types";

export const signInRequestCase = (
  state: AuthState,
  _: SignInRequestAction
): AuthState => ({
  ...state,
  errors: null,
  loading: true,
});

export const signInSuccessCase = (
  state: AuthState,
  _: SignInSuccessAction
): AuthState => ({
  ...state,
  errors: null,
  loading: false,
});

export const signInFailureCase = (
  state: AuthState,
  action: SignInFailureAction
): AuthState => ({
  ...state,
  loading: false,
  errors: action.payload.errors,
});
