import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call, select } from "redux-saga/effects";
import {
  getMilestone,
  getMilestoneRequest,
  getMilestoneSuccess,
  getMilestoneFailure,
} from "../../actions";
import { getMilestoneApi } from "../../api/tasks/getMilestone";
import { getMilestonesList } from "../../selectors";
import { Milestone } from "../../types";

export function* getMilestoneSaga(
  action: ActionType<typeof getMilestone>
): SagaIterator {
  yield put(getMilestoneRequest());
  try {
    const params = action.payload;
    const { errors, status, ...payload } = yield call(getMilestoneApi, params);
    if (errors || !payload.data) {
      yield put(getMilestoneFailure({ errors, status }));
      return;
    }
    const milestones = yield select(getMilestonesList);
    const newMilestones: Milestone[] = [
      ...milestones.filter(
        (milestone: Milestone) => milestone.id !== payload.data.id
      ),
      payload.data,
    ];

    yield put(getMilestoneSuccess({ data: newMilestones }));
  } catch (e) {
    yield put(getMilestoneFailure({ errors: e || "Generic error" }));
  }
}
