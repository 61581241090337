import React, { useCallback } from "react";
import { Flex, Spacer } from "../../atoms";
import { Tooltip } from "@material-ui/core";
import { IonButton, IonIcon } from "@ionic/react";
import {
  hourglassOutline,
  construct,
  addOutline,
  checkmarkDone,
} from "ionicons/icons";
import { useMediaQuery } from "../../../hooks";
import shortid from "shortid";

type Props = {};

type Action = { icon: string; action: () => void; label: string };

const TasksNavigation: React.FC<Props> = () => {
  const { isMobile } = useMediaQuery();
  const actions: Action[] = [
    { icon: hourglassOutline, action: () => null, label: "Over due" },
    { icon: construct, action: () => null, label: "In progress" },
    { icon: addOutline, action: () => null, label: "New tasks" },
    { icon: checkmarkDone, action: () => null, label: "Completed" },
  ];

  const renderAction = useCallback(
    (action: Action) => {
      if (isMobile) {
        return (
          <IonButton
            key={shortid.generate()}
            fill="clear"
            color="dark"
            onClick={action.action}
          >
            <Flex flexDirection="column">
              <IonIcon icon={action.icon} size="large" />
              <Spacer height="10px" />
              <small>{action.label}</small>
            </Flex>
          </IonButton>
        );
      }
      return (
        <Tooltip key={shortid.generate()} title={action.label} placement="top">
          <IonButton fill="clear" color="dark" onClick={action.action}>
            <IonIcon icon={action.icon} />
          </IonButton>
        </Tooltip>
      );
    },
    [isMobile]
  );

  return (
    <Flex
      flexDirection={isMobile ? "row" : "column"}
      justifyContent="space-around"
    >
      {actions.map((action) => renderAction(action))}
    </Flex>
  );
};

TasksNavigation.displayName = "TasksNavigation";

export default React.memo(TasksNavigation);
