import { fetch } from "../../utils/fetch";
import { MMS_URL } from "../../constants";
import { GetTasksSuccessResponse, GetTasksFailureResponse } from "../../types";

type Response = GetTasksSuccessResponse | GetTasksFailureResponse;

export const getMyTasksApi = async (): Promise<Response> => {
  try {
    const response: Response = await fetch.get({
      url: `${MMS_URL}projects/my-projects?perPage=999`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
