import { createAction } from "typesafe-actions";
import { GetNotificationsSuccessResponse, GetNotificationsFailureResponse } from "../../types";

export const getNotifications = createAction("trigger/GET_NOTIFICATIONS")();
export const getNotificationsRequest = createAction("action/GET_NOTIFICATIONS_REQUEST")();
export const getNotificationsSuccess = createAction("action/GET_NOTIFICATIONS_SUCCESS")<
  GetNotificationsSuccessResponse['data']
>();
export const getNotificationsFailure = createAction("action/GET_NOTIFICATIONS_FAILURE")<
  GetNotificationsFailureResponse
>();
