import React from "react";
import { Flex } from "../../atoms";

const Empty: React.FC = () => {
  return (
    <Flex>
      <h3 style={{ color: "var(--ion-color-light)", textAlign: "center" }}>
        Nothing to show here
      </h3>
    </Flex>
  );
};

Empty.displayName = "Empty";

export default React.memo(Empty);
