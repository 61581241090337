import {
  TasksState,
  UpdateTaskSuccessAction,
  UpdateTaskFailureAction,
  UpdateTaskRequestAction,
} from "../../types";

export const updateTaskRequestCase = (
  state: TasksState,
  _: UpdateTaskRequestAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    loading: true,
  },
});

export const updateTaskSuccessCase = (
  state: TasksState,
  action: UpdateTaskSuccessAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    loading: false,
    loaded: true,
    data: action.payload,
  },
});

export const updateTaskFailureCase = (
  state: TasksState,
  action: UpdateTaskFailureAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    errors: action.payload.errors,
    loading: false,
    loaded: true,
  },
});
