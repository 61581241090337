import { createAction } from "typesafe-actions";
import {
  GetMilestonePayload,
  GetMilestoneFailureResponse,
  GetMilestoneSuccessResponse,
} from "../../types";

export const getMilestone = createAction("trigger/GET_MILESTONE")<
  GetMilestonePayload
>();
export const getMilestoneRequest = createAction(
  "action/GET_MILESTONE_REQUEST"
)();
export const getMilestoneSuccess = createAction("action/GET_MILESTONE_SUCCESS")<
  GetMilestoneSuccessResponse
>();
export const getMilestoneFailure = createAction("action/GET_MILESTONE_FAILURE")<
  GetMilestoneFailureResponse
>();
