import React from "react";
import { IonPage } from "@ionic/react";
import { Link } from "react-router-dom";
import { LoginForm } from "../../components/organisms";
import { Logo, Flex, Spacer } from "../../components/atoms";
import { Typography } from "@material-ui/core";

const LoginScreen: React.FC = () => {
  return (
    <IonPage>
      <Flex flexDirection="column">
        <Logo width="300px" />
        <Typography variant="h4" color="textPrimary">
          Login
        </Typography>
        <LoginForm />
        <Flex height="50px">
          <Link to="./sign-up">Create an account</Link>
          <Spacer width="10px" />
          <Link to="./recover-password">Forgot password</Link>
        </Flex>
      </Flex>
    </IonPage>
  );
};

export default React.memo(LoginScreen);
