import { fetch } from "../../utils/fetch";
import { AUTH_URL } from "../../constants";
import {
  RefreshTokenPayload,
  RefreshTokenResponseSuccess,
  RefreshTokenResponseFailure,
} from "../../types";

type Response = RefreshTokenResponseSuccess | RefreshTokenResponseFailure;

export const refreshTokenApi = async (
  payload: RefreshTokenPayload
): Promise<Response> => {
  try {
    const response: Response = await fetch.get({
      url: `${AUTH_URL}users/${payload.sub}/refresh-token`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
