import { MONITORING_MS_URL } from '../../constants';
import { fetch } from '../../utils/fetch';

type Response = unknown;

export const copyScapeVerifyMilestoneApi = async (
  content: string,
): Promise<Response> => {
  try {
    const response: Response = await fetch.post({
      url: `${MONITORING_MS_URL}content-check/copyscape`,
      params: content,
      json: false,
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};
