import { takeEvery, takeLatest } from "redux-saga/effects";
import {
  SignInAction,
  GetUserInfoAction,
  SignUpAction,
  VerifyTokenAction,
  RefreshTokenAction,
  RecoverPasswordAction,
  ResetPasswordAction,
  UpdateProfileAction,
} from "../../types";
import { SagaIterator } from "redux-saga";
import { signInSaga } from "./signInSaga";
import { getUserInfoSaga } from "./getUserInfoSaga";
import { signOutSaga } from "./signOutSaga";
import { signUpSaga } from "./signUpSaga";
import { verifyTokenSaga } from "./verifyTokenSaga";
import { refreshTokenSaga } from "./refreshTokenSaga";
import { recoverPasswordSaga } from "./recoverPasswordSaga";
import { resetPasswordSaga } from "./resetPasswordSaga";
import { updateProfileSaga } from "./updateProfileSaga";

export const rootSagas = [
  takeEvery(
    "trigger/SIGN_IN",
    (action: SignInAction): SagaIterator => signInSaga(action)
  ),

  takeEvery("trigger/SIGN_OUT", (): SagaIterator => signOutSaga()),

  takeEvery(
    "trigger/GET_USER_INFO",
    (_: GetUserInfoAction): SagaIterator => getUserInfoSaga()
  ),

  takeLatest(
    "trigger/SIGN_UP",
    (action: SignUpAction): SagaIterator => signUpSaga(action)
  ),

  takeLatest(
    "trigger/UPDATE_PROFILE",
    (action: UpdateProfileAction): SagaIterator => updateProfileSaga(action)
  ),

  takeLatest(
    "trigger/VERIFY_TOKEN",
    (_: VerifyTokenAction): SagaIterator => verifyTokenSaga()
  ),

  takeLatest(
    "trigger/REFRESH_TOKEN",
    (action: RefreshTokenAction): SagaIterator => refreshTokenSaga(action)
  ),

  takeLatest(
    "trigger/RECOVER_PASSWORD",
    (action: RecoverPasswordAction): SagaIterator => recoverPasswordSaga(action)
  ),

  takeLatest(
    "trigger/RESET_PASSWORD",
    (action: ResetPasswordAction): SagaIterator => resetPasswordSaga(action)
  ),
];
