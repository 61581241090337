import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import { signOutRequest } from "../../actions";
import { removeAuthToken, removeExpirationDate } from "../../utils/storage";

export function* signOutSaga(): SagaIterator {
  yield put(signOutRequest());
  yield call(removeAuthToken);
  yield call(removeExpirationDate);
  window.location.reload();
}
