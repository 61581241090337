import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useAuthentication } from "../../../hooks";
import { Spacer, FormErrors, FormSubmit } from "../../atoms";
import { FormInput, ErrorToast } from "../../molecule";

const RecoverPasswordForm: React.FC = () => {
  const { handleSubmit, errors, control } = useForm();
  const { recoverPassword, errors: authErrors, loading } = useAuthentication();

  const onSubmit = useCallback(
    (values: any) => {
      recoverPassword(values);
    },
    [recoverPassword]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Spacer height="20px" />
      <FormInput name="email" type="email" control={control} required />
      {errors && <FormErrors errors={Object.values(errors)} />}
      <Spacer height="20px" />
      <FormSubmit label="Request new password" loading={loading} />
      <ErrorToast message={authErrors} />
    </form>
  );
};

RecoverPasswordForm.displayName = "RecoverPasswordForm";

export default React.memo(RecoverPasswordForm);
