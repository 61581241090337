import {
  MilestonesState,
  UpdateMilestoneSuccessAction,
  UpdateMilestoneFailureAction,
  UpdateMilestoneRequestAction,
} from "../../types";

export const updateMilestoneRequestCase = (
  state: MilestonesState,
  _: UpdateMilestoneRequestAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    errors: null,
    loaded: false,
    loading: true,
    success: false,
  },
});

export const updateMilestoneSuccessCase = (
  state: MilestonesState,
  action: UpdateMilestoneSuccessAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    data: action.payload,
    loading: false,
    loaded: true,
    success: true,
  },
});

export const updateMilestoneFailureCase = (
  state: MilestonesState,
  action: UpdateMilestoneFailureAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    errors: action.payload.errors,
    loading: false,
    loaded: true,
    success: false,
  },
});
