import React from "react";
import { IonButton, IonSpinner } from "@ionic/react";

type Props = {
  label: string;
  disabled?: boolean;
  loading?: boolean;
};

const FormSubmit: React.FC<Props> = ({ label, disabled, loading }) => {
  return (
    <IonButton
      type="submit"
      color="primary"
      shape="round"
      expand="block"
      fill="outline"
      disabled={disabled || loading}
    >
      {loading && <IonSpinner name="bubbles" style={{ marginRight: 15 }} />}
      {label}
    </IonButton>
  );
};

FormSubmit.displayName = "FormSubmit";

export default React.memo(FormSubmit);
