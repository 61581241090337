import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useAuthentication } from "../../../hooks";
import { Spacer, FormErrors, FormSubmit } from "../../atoms";
import { FormInput, ErrorToast } from "../../molecule";
import { LANGUAGES, PAYMENT_PREFERENCES } from "../../../store/constants";

const SignUpForm: React.FC = () => {
  const { handleSubmit, errors, control } = useForm();
  const { register, errors: authErrors, loading } = useAuthentication();

  const onSubmit = useCallback(
    (values: any) => {
      const {
        languages,
        street,
        city,
        country,
        state,
        note,
        postal_code,
        ...payload
      } = values;
      register({
        ...payload,
        tags: { languages },
        addresses: [
          {
            street,
            city,
            state,
            country,
            note,
            is_primary: true,
            is_billing: false,
            is_shipping: false,
          },
        ],
      });
    },
    [register]
  );

  const validateEmail = useCallback((value) => {
    if (value.indexOf("@yahoo") > -1 || value.indexOf("@outlook") > -1) {
      return "We are temporarily experiencing problems with Yahoo and Outlook emails, please insert a different email";
    }
    return true;
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Spacer height="20px" />
      <FormInput name="paypal_email" type="email" control={control} required />
      <FormInput
        name="email"
        type="email"
        control={control}
        required
        rules={{
          validate: (value: any) => {
            return validateEmail(value);
          },
        }}
      />
      <FormInput name="name" label="First name" control={control} required />
      <FormInput name="last_name" control={control} required />
      <FormInput
        name="languages"
        control={control}
        options={LANGUAGES}
        multiple
        required
      />
      <FormInput name="street" control={control} />
      <FormInput name="country" control={control} />
      <FormInput name="city" control={control} />
      <FormInput name="state" control={control} />
      <FormInput name="note" control={control} />
      <FormInput name="postal_code" control={control} />
      <FormInput
        options={PAYMENT_PREFERENCES}
        name="payment_preference"
        control={control}
        required
      />
      {errors && <FormErrors errors={Object.values(errors)} />}
      <Spacer height="20px" />
      <FormSubmit label="Sign Up" loading={loading} />
      <ErrorToast message={authErrors} />
    </form>
  );
};

SignUpForm.displayName = "SignUpForm";

export default React.memo(SignUpForm);
