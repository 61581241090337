import { fetch } from "../../utils/fetch";
import { AUTH_URL } from "../../constants";
import {
  RecoverPasswordPayload,
  RecoverPasswordFailureResponse,
  RecoverPasswordSuccessResponse,
} from "../../types";

type Response = RecoverPasswordFailureResponse | RecoverPasswordSuccessResponse;

export const recoverPasswordApi = async (
  payload: RecoverPasswordPayload
): Promise<Response> => {
  let formData = new FormData();
  formData.append("email", payload.email);
  try {
    const response: Response = await fetch.post({
      url: `${AUTH_URL}users/forgot-password`,
      params: formData,
      formData: true,
    });
    return response;
  } catch (e) {
    return e;
  }
};
