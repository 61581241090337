import React from "react";
import styled from "styled-components";
import shortid from "shortid";

type Error = {
  type: string;
  message: string;
  ref?: string;
};

type Props = {
  errors: any[];
};

const StyledError = styled.li`
  color: var(--ion-color-danger);
`;

const FormErrors: React.FC<Props> = ({ errors }) => {
  return (
    <ul>
      {errors.map((error) => (
        <StyledError key={shortid.generate()} data-cy="form-error">
          {error.message || error.type}
        </StyledError>
      ))}
    </ul>
  );
};

FormErrors.displayName = "FormErrors";

export default React.memo(FormErrors);
