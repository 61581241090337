import { createAction } from "typesafe-actions";
import {
  ResetPasswordPayload,
  ResetPasswordFailureResponse,
} from "../../types";

export const resetPassword = createAction("trigger/RESET_PASSWORD")<
  ResetPasswordPayload
>();
export const resetPasswordRequest = createAction(
  "action/RESET_PASSWORD_REQUEST"
)();
export const resetPasswordSuccess = createAction(
  "action/RESET_PASSWORD_SUCCESS"
)();
export const resetPasswordFailure = createAction(
  "action/RESET_PASSWORD_FAILURE"
)<ResetPasswordFailureResponse>();
export const resetPasswordInit = createAction("action/RESET_PASSWORD_INIT")();
