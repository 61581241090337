import { stringify } from "querystring";
import { fetch } from "../../utils/fetch";
import { MMS_URL } from "../../constants";
import {
  GetTasksSuccessResponse,
  GetTasksFailureResponse,
  User,
} from "../../types";

type Response = GetTasksSuccessResponse | GetTasksFailureResponse;

export const getAvailableTasksApi = async (user: User): Promise<Response> => {
  try {
    const params = {
      filter: JSON.stringify({
        projectAttributes: {
          key: "language",
          value: { where_in: user?.tags?.languages ?? [] },
        },
      }),
      perPage: 999,
    };
    const response: Response = await fetch.get({
      url: `${MMS_URL}projects/available?${stringify(params)}`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
