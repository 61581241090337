import React from "react";
import { Editor as TinyEditor } from "@tinymce/tinymce-react";
import { EventHandler } from "@tinymce/tinymce-react/lib/cjs/main/ts/Events";
import { useAuthentication } from "../../../hooks";
import { useComments } from "../../../hooks/ui/useComments";

type Props = {
  initialValue?: string;
  value?: string;
  onEditorChange?: EventHandler<any>;
  image_list?: { title: string | undefined; value: string }[];
  disabled?: boolean;
  project_id: string;
};

const Editor: React.FC<Props> = ({
  initialValue,
  value,
  onEditorChange,
  image_list,
  disabled,
  project_id,
}) => {
  const { user } = useAuthentication();
  const {
    tinycomments_create,
    tinycomments_reply,
    tinycomments_edit_comment,
    tinycomments_delete,
    tinycomments_delete_all,
    tinycomments_delete_comment,
    tinycomments_lookup,
  } = useComments({
    project_id,
  });
  return (
    <TinyEditor
      disabled={disabled}
      initialValue={initialValue}
      value={value}
      onEditorChange={onEditorChange}
      apiKey="oszv2ihtitnjrbwlofgz458gspw3zu82t74tx3pplkt86nyh"
      init={{
        height: 500,
        menubar: true,
        image_list,
        tinycomments_mode: "callbacks",
        tinycomments_create,
        tinycomments_reply,
        tinycomments_delete,
        tinycomments_delete_all,
        tinycomments_delete_comment,
        tinycomments_lookup,
        tinycomments_edit_comment,
        tinycomments_author: user?.name + " " + user?.last_name,
        plugins: [
          "advlist paste tinycomments autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks fullscreen spellchecker",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | image | bullist numlist outdent indent | removeformat | addcomment showcomments | spellchecker help",
      }}
    />
  );
};

Editor.displayName = "Editor";

export default React.memo(Editor);
