import {
  AuthState,
  VerifyTokenSuccessAction,
  VerifyTokenFailureAction,
  VerifyTokenRequestAction,
} from "../../types";

export const verifyTokenRequestCase = (
  state: AuthState,
  _: VerifyTokenRequestAction
): AuthState => ({
  ...state,
  loading: true,
});

export const verifyTokenSuccessCase = (
  state: AuthState,
  _: VerifyTokenSuccessAction
): AuthState => ({
  ...state,
  loading: false,
});

export const verifyTokenFailureCase = (
  state: AuthState,
  _: VerifyTokenFailureAction
): AuthState => ({
  ...state,
  loading: false,
});
