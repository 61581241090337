import {
  MilestonesState,
  GetMilestonesSuccessAction,
  GetMilestonesFailureAction,
  GetMilestonesRequestAction,
} from "../../types";

export const getMilestonesRequestCase = (
  state: MilestonesState,
  _: GetMilestonesRequestAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    loaded: false,
    data: [],
    loading: true,
  },
});

export const getMilestonesSuccessCase = (
  state: MilestonesState,
  action: GetMilestonesSuccessAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    data: action.payload,
    loading: false,
    loaded: true,
  },
});

export const getMilestonesFailureCase = (
  state: MilestonesState,
  action: GetMilestonesFailureAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    errors: action.payload.errors,
    loading: false,
    loaded: true,
  },
});
