import { createAction } from "typesafe-actions";
import {
  SignUpPayload,
  SignUpSuccessResponse,
  SignUpFailureResponse,
} from "../../types";

export const signUp = createAction("trigger/SIGN_UP")<SignUpPayload>();
export const signUpRequest = createAction("action/SIGN_UP_REQUEST")();
export const signUpSuccess = createAction("action/SIGN_UP_SUCCESS")<
  SignUpSuccessResponse
>();
export const signUpFailure = createAction("action/SIGN_UP_FAILURE")<
  SignUpFailureResponse
>();
