import { createAction } from "typesafe-actions";
import {
  RecoverPasswordPayload,
  RecoverPasswordFailureResponse,
} from "../../types";

export const recoverPassword = createAction("trigger/RECOVER_PASSWORD")<
  RecoverPasswordPayload
>();
export const recoverPasswordInit = createAction(
  "trigger/RECOVER_PASSWORD_INIT"
)();
export const recoverPasswordRequest = createAction(
  "action/RECOVER_PASSWORD_REQUEST"
)();
export const recoverPasswordSuccess = createAction(
  "action/RECOVER_PASSWORD_SUCCESS"
)();
export const recoverPasswordFailure = createAction(
  "action/RECOVER_PASSWORD_FAILURE"
)<RecoverPasswordFailureResponse>();
