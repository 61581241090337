import React from "react";
import styled from "styled-components";
import logo from "../../../assets/img/logo.png";

type Props = {
  width?: string;
  height?: string;
};

const StyledLogo = styled.img<Props>`
  width: ${({ width = "200px" }) => width};
  height: ${({ height = "auto" }) => height};
`;

const Logo: React.FC<Props> = ({ width, height }) => {
  return <StyledLogo src={logo} alt="MangoStudio.net" />;
};

Logo.displayName = "Logo";

export default React.memo(Logo);
