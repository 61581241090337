import {
  UpdateAllNotificationsReadSuccessAction,
  UpdateAllNotificationsReadFailureAction,
  UpdateAllNotificationsReadRequestAction,
  NotificationsState,
} from "../../types";

export const updateAllNotificationsReadRequestCase = (
  state: NotificationsState,
  _: UpdateAllNotificationsReadRequestAction
): NotificationsState => ({
  ...state,
  loading: true,
});

export const updateAllNotificationsReadSuccessCase = (
  state: NotificationsState,
  action: UpdateAllNotificationsReadSuccessAction
): NotificationsState => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

export const updateAllNotificationsReadFailureCase = (
  state: NotificationsState,
  action: UpdateAllNotificationsReadFailureAction
): NotificationsState => ({
  ...state,
  errors: action.payload.errors,
  loading: false,
  loaded: true,
});
