import { createAction } from "typesafe-actions";
import {
  GetTaskPayload,
  GetTaskSuccessResponse,
  GetTaskFailureResponse,
} from "../../types/tasks/getTask";

export const getTask = createAction("trigger/GET_TASK")<GetTaskPayload>();
export const getTaskRequest = createAction("action/GET_TASK_REQUEST")();
export const getTaskSuccess = createAction("action/GET_TASK_SUCCESS")<
  GetTaskSuccessResponse
>();
export const getTaskFailure = createAction("action/GET_TASK_FAILURE")<
  GetTaskFailureResponse
>();
