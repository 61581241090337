import { fetch } from "../../utils/fetch";
import { NMS_URL } from "../../constants";
import {
  UpdateAllNotificationsReadSuccessResponse,
  UpdateAllNotificationsReadFailureResponse,
} from "../../types";

type Response =
  | UpdateAllNotificationsReadSuccessResponse
  | UpdateAllNotificationsReadFailureResponse;

export const updateAllNotificationsReadApi = async (): Promise<Response> => {
  try {
    const response: Response = await fetch.post({
      url: `${NMS_URL}notifications/mark-all-read`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
