import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call } from "redux-saga/effects";
import {
  getMilestones,
  getMilestonesRequest,
  getMilestonesSuccess,
  getMilestonesFailure,
} from "../../actions";
import { getMilestonesApi } from "../../api/tasks/getMilestones";

export function* getMilestonesSaga(
  action: ActionType<typeof getMilestones>
): SagaIterator {
  yield put(getMilestonesRequest());
  try {
    const params = action.payload;
    const { errors, status, ...payload } = yield call(getMilestonesApi, params);

    if (errors || !payload.data) {
      yield put(getMilestonesFailure({ errors, status }));
      return;
    }
    yield put(getMilestonesSuccess(payload.data));
  } catch (e) {
    yield put(getMilestonesFailure({ errors: e || "Generic error" }));
  }
}
