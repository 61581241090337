import React from "react";
import { IonHeader, IonToolbar } from "@ionic/react";
import NotificationsButton from "../NotificationsButton";
import { Flex, Logo } from "../../atoms";
import MenuButton from "../MenuButton";
import HelpButton from "../HelpButton";

type Props = {};

const DashboardHeader: React.FC<Props> = () => {
  return (
    <IonHeader>
      <IonToolbar style={{ paddingLeft: 5, paddingRight: 5 }}>
        <MenuButton />
        <Flex>
          <Logo />
        </Flex>
        <HelpButton />
        <NotificationsButton />
      </IonToolbar>
    </IonHeader>
  );
};

DashboardHeader.displayName = "DashboardHeader";

export default React.memo(DashboardHeader);
