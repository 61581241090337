import { fetch } from "../../utils/fetch";
import { MMS_URL } from "../../constants";
import {
  UpdateMilestoneSuccessResponse,
  UpdateMilestoneFailureResponse,
  UpdateMilestonePayload,
} from "../../types/tasks/updateMilestone";

type Response = UpdateMilestoneSuccessResponse | UpdateMilestoneFailureResponse;

export const updateMilestoneApi = async (
  payload: UpdateMilestonePayload
): Promise<Response> => {
  try {
    const response: Response = await fetch.put({
      url: `${MMS_URL}milestones/${payload.milestone_id}`,
      params: JSON.stringify(payload.data),
    });
    return response;
  } catch (e) {
    return e;
  }
};
