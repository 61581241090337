import decode from "jwt-decode";
import { fetch } from "../../utils/fetch";
import { AUTH_URL } from "../../constants";
import {
  ResetPasswordSuccessResponse,
  ResetPasswordFailureResponse,
  ResetPasswordPayload,
} from "../../types";

type Response = ResetPasswordSuccessResponse | ResetPasswordFailureResponse;

export const resetPasswordApi = async (
  payload: ResetPasswordPayload
): Promise<Response> => {
  let formData = new FormData();
  const token: any = decode(payload.token);
  formData.append("token", payload.token);
  formData.append("new_password", payload.newPassword);
  formData.append("verify_password", payload.verifyPassword);
  try {
    const response: Response = await fetch.post({
      url: `${AUTH_URL}users/${token.sub}/reset-password`,
      params: formData,
      formData: true,
    });
    return response;
  } catch (e) {
    return e;
  }
};
