import { SagaIterator } from "redux-saga";
import { put, call, select, take } from "redux-saga/effects";
import { getMyTasksApi } from "../../api/tasks/getMyTasks";
import { getAvailableTasksApi } from "../../api/tasks/getAvailableTasks";
import {
  getTasksRequest,
  getTasksSuccess,
  getTasksFailure,
} from "../../actions";
import { getAssignedTasksApi } from "../../api/tasks/getAssignedTasks";
import { getUserInfo } from "../../selectors";

export function* getTasksSaga(): SagaIterator {
  yield put(getTasksRequest());
  try {
    let user = yield select(getUserInfo);
    while (!user) {
      yield take();
      user = yield select(getUserInfo);
    }
    const { errors, status, ...payload } = yield call(getMyTasksApi);
    const {
      errors: availableErrors,
      status: availableStatus,
      ...availablePayload
    } = yield call(getAvailableTasksApi, user);
    const {
      errors: assignedErrors,
      status: assignedStatus,
      ...assignedPayload
    } = yield call(getAssignedTasksApi);
    if (
      errors ||
      availableErrors ||
      assignedErrors ||
      !assignedPayload.data ||
      !availablePayload.data ||
      !payload.data
    ) {
      yield put(
        getTasksFailure({
          errors: errors || availableErrors || assignedErrors,
          status: status || availableStatus || assignedStatus,
        })
      );
      return;
    }
    yield put(
      getTasksSuccess([
        ...payload.data,
        ...availablePayload.data,
        ...assignedPayload.data,
      ])
    );
  } catch (e) {
    yield put(getTasksFailure({ errors: e || "Generic error" }));
  }
}
