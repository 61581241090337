import { getAuthToken } from "../utils/storage";

export const TOKEN = getAuthToken();

export const LANGUAGES = [
  { id: "GE", name: "German" },
  { id: "EN_UK", name: "English UK" },
  { id: "EN_US", name: "English US" },
  { id: "TH", name: "Thai" },
  { id: "NL", name: "Dutch" },
  { id: "FR", name: "French" },
  { id: "JP", name: "Japanese" },
  { id: "ES", name: "Spanish" },
  { id: "ES_CA", name: "Spanish (Catalan)" },
  { id: "ES_CO", name: "Spanish (Colombian)" },
  { id: "IT", name: "Italian" },
];

export const PAYMENT_PREFERENCES = [
  { id: "after_task", name: "After Task" },
  { id: "end_of_month", name: "End of Month" },
];

export const USER_TAGS = {
  languages: LANGUAGES,
};
