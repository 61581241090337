import { fetch } from "../../utils/fetch";
import { MMS_URL } from "../../constants";
import { GetTaskSuccessResponse, GetTaskFailureResponse } from "../../types";
import { GetTaskPayload } from "../../types/tasks/getTask";

type Response = GetTaskSuccessResponse | GetTaskFailureResponse;

export const getTaskApi = async (
  payload: GetTaskPayload
): Promise<Response> => {
  try {
    const response: Response = await fetch.get({
      url: `${MMS_URL}projects/${payload.task_id}/view`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
