import { createAction } from "typesafe-actions";
import {
  UpdateAllNotificationsReadFailureResponse,
  UpdateAllNotificationsReadSuccessResponse,
} from "../../types";

export const updateAllNotificationsRead = createAction(
  "trigger/UPDATE_ALL_NOTIFICATIONS_READ"
)();
export const updateAllNotificationsReadRequest = createAction(
  "action/UPDATE_ALL_NOTIFICATIONS_READ_REQUEST"
)();
export const updateAllNotificationsReadSuccess = createAction(
  "action/UPDATE_ALL_NOTIFICATIONS_READ_SUCCESS"
)<UpdateAllNotificationsReadSuccessResponse['data']>();
export const updateAllNotificationsReadFailure = createAction(
  "action/UPDATE_ALL_NOTIFICATIONS_READ_FAILURE"
)<UpdateAllNotificationsReadFailureResponse>();
