import React from "react";
import { IonSpinner } from "@ionic/react";
import { Flex } from "../../atoms";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { useMediaQuery, useTasksList } from "../../../hooks";
import { useHistory } from "react-router";
import { TaskGroup } from "../../../store/types";

type Props = {};

type TCard = {
  title: string;
  subheader: string;
  id: TaskGroup;
  CTAText: string;
};

const cards: TCard[] = [
  {
    title: "Overdue",
    subheader: "The total of mangos you have overdue",
    id: "over_due",
    CTAText: "Check the overdue projects",
  },
  {
    title: "Active",
    subheader: "The total of mangos you have hands on right now",
    id: "in_progress",
    CTAText: "Check active projects",
  },
  {
    title: "Completed",
    subheader: "The total of mangos you have completed until now",
    id: "completed",
    CTAText: "Check completed projects",
  },
  {
    title: "Available",
    subheader: "The total of mangos available for you to pluck",
    id: "new_projects",
    CTAText: "Find new projects",
  },
];

const ManguitoChart: React.FC<Props> = () => {
  const history = useHistory();
  const { isMobile } = useMediaQuery();
  const { loading, tasksGrouped } = useTasksList();

  return (
    <Flex flexDirection="column">
      <Grid container spacing={3} style={{ flexGrow: 1 }}>
        {cards.map((card, i) => (
          <Grid item xs={6} key={`card-${i}`}>
            <Card variant="outlined" style={{ height: "100%" }}>
              <CardHeader
                title={card.title}
                subheader={!isMobile ? card.subheader : undefined}
                subheaderTypographyProps={{ variant: "body2" }}
              />
              <CardContent>
                <Typography variant="h2" align="center">
                  <b>
                    {loading ? (
                      <IonSpinner name="dots" />
                    ) : (
                        tasksGrouped[card.id].length
                      )}
                  </b>
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => history.push(`/tasks/${card.id}`)}>
                  {card.CTAText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Flex>
  );
};

ManguitoChart.displayName = "ManguitoChart";

export default React.memo(ManguitoChart);
