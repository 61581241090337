import { FetchParams } from '../../types';
import { raw } from './raw';
import { get } from './get';
import { put } from './put';
import { post } from './post';
import { deleteRequest } from './delete';

export interface Fetch {
  raw: <T>(params: FetchParams) => Promise<T>;
  get: <T>(params: FetchParams) => Promise<T>;
  put: <T>(params: FetchParams) => Promise<T>;
  post: <T>(params: FetchParams) => Promise<T>;
  delete: <T>(params: FetchParams) => Promise<T>;
}

export const fetch: Fetch = {
  raw,
  get,
  put,
  post,
  delete: deleteRequest,
};
