import { createReducer } from "typesafe-actions";
import {
  signInSuccess,
  signInFailure,
  signInRequest,
  getUserInfoRequest,
  getUserInfoSuccess,
  getUserInfoFailure,
  signOutRequest,
  signUpRequest,
  signUpSuccess,
  signUpFailure,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  verifyTokenRequest,
  verifyTokenSuccess,
  verifyTokenFailure,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,
  recoverPasswordRequest,
  recoverPasswordFailure,
  recoverPasswordSuccess,
  recoverPasswordInit,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  resetPasswordInit,
} from "../../actions";
import {
  signInSuccessCase,
  signInFailureCase,
  signInRequestCase,
} from "./signIn";
import { AuthState, AuthActions } from "../../types";
import {
  getUserInfoRequestCase,
  getUserInfoFailureCase,
  getUserInfoSuccessCase,
} from "./userInfo";
import { signOutRequestCase } from "./signOut";
import {
  signUpRequestCase,
  signUpSuccessCase,
  signUpFailureCase,
} from "./signUp";
import {
  updateProfileRequestCase,
  updateProfileSuccessCase,
  updateProfileFailureCase,
} from "./updateProfile";
import {
  verifyTokenRequestCase,
  verifyTokenFailureCase,
  verifyTokenSuccessCase,
} from "./verifyToken";
import {
  refreshTokenFailureCase,
  refreshTokenSuccessCase,
  refreshTokenRequestCase,
} from "./refreshToken";
import {
  recoverPasswordRequestCase,
  recoverPasswordFailureCase,
  recoverPasswordSuccessCase,
  recoverPasswordInitCase,
} from "./recoverPassword";
import {
  resetPasswordRequestCase,
  resetPasswordSuccessCase,
  resetPasswordFailureCase,
  resetPasswordInitCase,
} from "./resetPassword";

const initialState = {
  errors: null,
  loading: false,
  user: null,
};

export const authReducer = createReducer<AuthState, AuthActions>(initialState)
  .handleAction(signInRequest, signInRequestCase)
  .handleAction(signInSuccess, signInSuccessCase)
  .handleAction(signInFailure, signInFailureCase)
  .handleAction(signOutRequest, signOutRequestCase)
  .handleAction(getUserInfoRequest, getUserInfoRequestCase)
  .handleAction(getUserInfoSuccess, getUserInfoSuccessCase)
  .handleAction(getUserInfoFailure, getUserInfoFailureCase)
  .handleAction(signUpRequest, signUpRequestCase)
  .handleAction(signUpSuccess, signUpSuccessCase)
  .handleAction(signUpFailure, signUpFailureCase)
  .handleAction(updateProfileRequest, updateProfileRequestCase)
  .handleAction(updateProfileSuccess, updateProfileSuccessCase)
  .handleAction(updateProfileFailure, updateProfileFailureCase)
  .handleAction(verifyTokenRequest, verifyTokenRequestCase)
  .handleAction(verifyTokenSuccess, verifyTokenSuccessCase)
  .handleAction(verifyTokenFailure, verifyTokenFailureCase)
  .handleAction(refreshTokenRequest, refreshTokenRequestCase)
  .handleAction(refreshTokenSuccess, refreshTokenSuccessCase)
  .handleAction(refreshTokenFailure, refreshTokenFailureCase)
  .handleAction(recoverPasswordRequest, recoverPasswordRequestCase)
  .handleAction(recoverPasswordSuccess, recoverPasswordSuccessCase)
  .handleAction(recoverPasswordFailure, recoverPasswordFailureCase)
  .handleAction(recoverPasswordInit, recoverPasswordInitCase)
  .handleAction(resetPasswordRequest, resetPasswordRequestCase)
  .handleAction(resetPasswordSuccess, resetPasswordSuccessCase)
  .handleAction(resetPasswordFailure, resetPasswordFailureCase)
  .handleAction(resetPasswordInit, resetPasswordInitCase);
