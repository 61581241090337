import { createReducer } from "typesafe-actions";
import {
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFailure,
  updateNotificationReadRequest,
  updateNotificationReadSuccess,
  updateNotificationReadFailure,
  updateAllNotificationsReadRequest,
  updateAllNotificationsReadSuccess,
  updateAllNotificationsReadFailure,
} from "../../actions";
import {
  getNotificationsRequestCase,
  getNotificationsFailureCase,
  getNotificationsSuccessCase,
} from "./getNotifications";
import { NotificationsState, NotificationsActions } from "../../types";
import {
  updateNotificationReadRequestCase,
  updateNotificationReadSuccessCase,
  updateNotificationReadFailureCase,
} from "./updateNotificationRead";
import {
  updateAllNotificationsReadRequestCase,
  updateAllNotificationsReadSuccessCase,
  updateAllNotificationsReadFailureCase,
} from "./updateAllNotificationsRead";

const initialState = {
  loading: false,
  loaded: false,
  data: [],
  errors: null,
};

export const notificationsReducer = createReducer<
  NotificationsState,
  NotificationsActions
>(initialState)
  .handleAction(getNotificationsRequest, getNotificationsRequestCase)
  .handleAction(getNotificationsSuccess, getNotificationsSuccessCase)
  .handleAction(getNotificationsFailure, getNotificationsFailureCase)
  .handleAction(
    updateNotificationReadRequest,
    updateNotificationReadRequestCase
  )
  .handleAction(
    updateNotificationReadSuccess,
    updateNotificationReadSuccessCase
  )
  .handleAction(
    updateNotificationReadFailure,
    updateNotificationReadFailureCase
  )
  .handleAction(
    updateAllNotificationsReadRequest,
    updateAllNotificationsReadRequestCase
  )
  .handleAction(
    updateAllNotificationsReadSuccess,
    updateAllNotificationsReadSuccessCase
  )
  .handleAction(
    updateAllNotificationsReadFailure,
    updateAllNotificationsReadFailureCase
  );
