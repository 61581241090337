import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useAuthentication } from "../../../hooks";
import { Spacer, FormErrors, FormSubmit } from "../../atoms";
import { FormInput, ErrorToast } from "../../molecule";

const LoginForm: React.FC = () => {
  const { handleSubmit, errors, control } = useForm();
  const { login, errors: authErrors, loading } = useAuthentication();

  const onSubmit = useCallback(
    (values: any) => {
      login(values);
    },
    [login]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Spacer height="20px" />
      <FormInput name="email" type="email" control={control} required />
      <FormInput name="password" type="password" control={control} required />
      {errors && <FormErrors errors={Object.values(errors)} />}
      <Spacer height="20px" />
      <FormSubmit label="Login" loading={loading} />
      <ErrorToast message={authErrors} />
    </form>
  );
};

LoginForm.displayName = "LoginForm";

export default React.memo(LoginForm);
