import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call, select } from "redux-saga/effects";
import {
  updateTask,
  updateTaskRequest,
  updateTaskSuccess,
  updateTaskFailure,
} from "../../actions";
import { updateTasksApi } from "../../api/tasks/updateTask";
import { getTasksList } from "../../selectors";
import { Task } from "../../types";

export function* updateTaskSaga(
  action: ActionType<typeof updateTask>
): SagaIterator {
  yield put(updateTaskRequest());
  try {
    const params = action.payload;
    const { errors, status, data } = yield call(updateTasksApi, params);
    if (errors || !data || !data.id) {
      yield put(updateTaskFailure({ errors, status }));
      return;
    }

    const tasks = yield select(getTasksList);
    const newTasks = tasks.filter((task: Task) => task.id !== data.id);
    yield put(updateTaskSuccess([...newTasks, data]));
  } catch (e) {
    yield put(updateTaskFailure({ errors: e || "Generic error" }));
  }
}
