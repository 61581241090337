import React from "react";
import { useMenuList } from "../../../hooks";
import { IonList, IonItem, IonIcon, IonRippleEffect } from "@ionic/react";
import shortid from "shortid";

type Props = {};

const MainMenu: React.FC<Props> = () => {
  const actions = useMenuList();
  return (
    <IonList>
      {actions.map((action) => (
        <IonItem
          key={shortid.generate()}
          onClick={action.handler}
          className="ion-activatable ripple-parent"
        >
          <>
            <IonIcon icon={action.icon} slot="start" />
            <span>{action.text}</span>
            <IonRippleEffect type="unbounded" />
          </>
        </IonItem>
      ))}
    </IonList>
  );
};

MainMenu.displayName = "MainMenu";

export default React.memo(MainMenu);
