import React from "react";
import { useNotifications } from "../../../hooks/notifications/useNotifications";
import { List, Box, Divider, Link } from "@material-ui/core";
import { Notification } from "../../molecule";
import { CloseRounded } from "@material-ui/icons";

type Props = {
  onClose: () => void;
};

const NotificationsList: React.FC<Props> = ({ onClose }) => {
  const { notifications, markAllNotificationsRead } = useNotifications();
  const unreadNotifications = notifications.filter(
    (notification) => !notification.read
  );

  return (
    <List>
      {!notifications.length && (
        <>
          <Box
            padding={1}
            style={{ display: "flex" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <span>No notifications to show</span>
            <CloseRounded color="primary" onClick={onClose} />
          </Box>
          <Divider />
        </>
      )}
      <>
        <Box
          padding={1}
          style={{ display: "flex" }}
          justifyContent="space-between"
          alignItems="center"
        >
          {!!unreadNotifications.length ? (
            <Link
              onClick={markAllNotificationsRead}
              href="#"
              color="textPrimary"
            >
              Mark all as read
            </Link>
          ) : (
            <span>Notifications</span>
          )}
          <CloseRounded color="primary" onClick={onClose} />
        </Box>
        <Divider />
      </>
      {notifications.map((notification) => (
        <Notification notification={notification} key={notification.id} />
      ))}
    </List>
  );
};

NotificationsList.displayName = "NotificationsList";

export default React.memo(NotificationsList);
