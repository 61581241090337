import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call, select } from "redux-saga/effects";
import {
  updateNotificationRead,
  updateNotificationReadRequest,
  updateNotificationReadSuccess,
  updateNotificationReadFailure,
} from "../../actions";
import { getNotificationsList } from "../../selectors/notifications";
import { updateNotificationReadApi } from "../../api";
import { Notification } from "../../types";

export function* updateNotificationReadSaga(
  action: ActionType<typeof updateNotificationRead>
): SagaIterator {
  yield put(updateNotificationReadRequest());
  try {
    const params = action.payload;
    const { errors, status, data } = yield call(
      updateNotificationReadApi,
      params
    );
    if (errors || !data || !data.id) {
      yield put(updateNotificationReadFailure({ errors, status }));
      return;
    }

    const notifications = yield select(getNotificationsList);
    const notificationIndex: number = notifications.findIndex(
      (notification: Notification) => notification.id === data.id
    );
    notifications.splice(notificationIndex, 1, data);
    yield put(updateNotificationReadSuccess(notifications));
  } catch (e) {
    yield put(updateNotificationReadFailure({ errors: e || "Generic error" }));
  }
}
