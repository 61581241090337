import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { Flex, Spacer } from "../../../atoms";
import { IonIcon, IonBadge, IonItemDivider, IonButton } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { TaskCard } from "../../../molecule";
import { Typography } from "@material-ui/core";
import { TaskGroup } from "../../../../store/types";
import { useTasksList } from "../../../../hooks/tasks/useTasksList";

type Props = {
  label: string;
  icon: string;
  section: TaskGroup;
  isOverview?: boolean;
};

const TaskOverviewSection: React.FC<Props> = ({
  label,
  icon,
  section,
  isOverview,
}) => {
  const history = useHistory();
  const { tasksGrouped } = useTasksList();
  const tasks = useMemo(
    () =>
      isOverview ? tasksGrouped[section].slice(0, 1) : tasksGrouped[section],
    [isOverview, tasksGrouped, section]
  );
  const goToTasks = useCallback(() => history.push(`/tasks/${section}`), [
    history,
    section,
  ]);

  const renderTasks = useMemo(() => {
    if (!tasks.length) {
      return <TaskCard.Empty />;
    }
    return (
      <>
        {tasks.map((task) => (
          <TaskCard key={task.id} task={task} />
        ))}
      </>
    );
  }, [tasks]);

  return (
    <div>
      <Flex justifyContent="space-between" className="ion-padding">
        <IonIcon icon={icon} size="large" />
        <Spacer width="10px" />
        <Flex justifyContent="start">
          <Typography variant="h6">
            {label}{" "}
            <IonBadge color={tasks.length ? "primary" : "light"}>
              {tasksGrouped[section].length}
            </IonBadge>
          </Typography>
        </Flex>
        {isOverview && tasks.length ? (
          <IonButton fill="clear" onClick={goToTasks}>
            View all
            <IonIcon slot="end" icon={chevronForward} />
          </IonButton>
        ) : null}
      </Flex>
      {renderTasks}
      <Spacer height="24px" />
      <IonItemDivider style={{ minHeight: 1 }} />
    </div>
  );
};

TaskOverviewSection.displayName = "TaskOverviewSection";

export default React.memo(TaskOverviewSection);
