import React, { useEffect, useMemo, useState } from "react";
import { IonPage, IonCard } from "@ionic/react";
import { Link, useHistory } from "react-router-dom";
import { ResetPasswordForm } from "../../components/organisms";
import { Logo, Flex, Spacer } from "../../components/atoms";
import { useAuthentication } from "../../hooks";
import queryString from "query-string";

const ResetPassword: React.FC = () => {
  const { resetPasswordSuccess } = useAuthentication();
  const { location, push } = useHistory();
  const urlParams = queryString.parse(location.search);
  const token = urlParams["reset-password"] as string;
  const [localToken, setLocalToken] = useState<string>();
  console.log(token, localToken);

  useEffect(() => {
    if (token) {
      setLocalToken(token)
      push('/reset-password');

    }
  }, [token, push])

  const ThankYouMessage = useMemo(
    () => (
      <IonCard style={{ padding: 30, textAlign: "center" }}>
        <div>
          <h2>Password updated successfully</h2>
          <p>
            You can now login and start plucking new Mangos{" "}
            <span aria-label="mango" role="img">
              🥭
            </span>
            <br /> <Link to="/login">GO TO LOGIN</Link>
          </p>
        </div>
      </IonCard>
    ),
    []
  );

  const NoTokenMessage = useMemo(
    () => (
      <IonCard style={{ padding: 30, textAlign: "center" }}>
        <div>
          <h2>
            Ooops{" "}
            <span aria-label="ops" role="img">
              🤷‍♂️
            </span>
          </h2>
          <p>
            You should access this page from the <br />
            <b>super secret magic link</b>
            <br />
            shared with you via email,
            <br />
            if you didn't receive it try to request it again{" "}
            <Link to="/recover-password">HERE</Link>
          </p>
        </div>
      </IonCard>
    ),
    []
  );

  return (
    <IonPage>
      <Flex flexDirection="column">
        <Logo width="300px" />
        {resetPasswordSuccess ? (
          ThankYouMessage
        ) : (
            <>
              <h2>Reset Password</h2>
              {localToken ? <ResetPasswordForm token={localToken} /> : NoTokenMessage}
              <Flex height="50px">
                <Link to="./login">Go to Login</Link>
                <Spacer width="10px" />
                <Link to="./sign-up">Create an account</Link>
              </Flex>
            </>
          )}
      </Flex>
    </IonPage>
  );
};

export default React.memo(ResetPassword);
