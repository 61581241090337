import { AppStore, TasksState, TasksGrouped, Task, MilestonesState } from "../../types";
import { createSelector } from "reselect";

function getTasksState(state: AppStore): TasksState {
  return state.tasks;
}

function getMilestonesState(state: AppStore): MilestonesState {
  return state.milestones;
}

export const getTasksList = createSelector(
  getTasksState,
  (tasksState): TasksState['tasks']['data'] => tasksState.tasks.data,
);

export const getTasksLoading = createSelector(
  getTasksState,
  (tasksState): TasksState['tasks']['loading'] => tasksState.tasks.loading,
);

export const getTasksLoaded = createSelector(
  getTasksState,
  (tasksState): TasksState['tasks']['loaded'] => tasksState.tasks.loaded,
);

export const getTasksErrors = createSelector(
  getTasksState,
  (tasksState): TasksState['tasks']['errors'] => tasksState.tasks.errors,
);

export const getTasksGrouped = createSelector(
  getTasksList,
  (tasksList): TasksGrouped => {
    const over_due: Task[] = [];
    const in_progress: Task[] = [];
    const new_projects: Task[] = [];
    const completed: Task[] = [];
    tasksList.forEach(task => {
      if (task.status === 'IN_PROGRESS' && task.due_date && parseInt(task.due_date) * 1000 < new Date().getTime()) {
        over_due.push(task)
        return;
      }
      if (task.status === 'APPROVED') {
        completed.push(task)
        return;
      }
      if (task.status === 'IN_PROGRESS') {
        in_progress.push(task)
        return;
      }
      if (task.status === 'NOT_PLUCKED') {
        new_projects.push(task)
        return;
      }
    });
    over_due.sort((a, b) => parseInt(a.due_date) - parseInt(b.due_date));
    in_progress.sort((a, b) => parseInt(a.due_date) - parseInt(b.due_date));
    new_projects.sort((a, b) => parseInt(a.due_date) - parseInt(b.due_date));
    completed.sort((a, b) => parseInt(a.due_date) - parseInt(b.due_date));
    return {
      over_due,
      in_progress,
      new_projects,
      completed,
    }
  },
);

export const getMilestonesList = createSelector(
  getMilestonesState,
  (milestonesState): MilestonesState['milestones']['data'] => milestonesState.milestones.data?.sort((a, b) => a.created_at < b.created_at ? -1 : 0),
);

export const getMilestonesLoading = createSelector(
  getMilestonesState,
  (milestonesState): MilestonesState['milestones']['loading'] => milestonesState.milestones.loading,
);

export const getMilestonesLoaded = createSelector(
  getMilestonesState,
  (milestonesState): MilestonesState['milestones']['loaded'] => milestonesState.milestones.loaded,
);

export const getMilestonesErrors = createSelector(
  getMilestonesState,
  (milestonesState): MilestonesState['milestones']['errors'] => milestonesState.milestones.errors,
);

export const getMilestonesSuccess = createSelector(
  getMilestonesState,
  (milestonesState): MilestonesState['milestones']['success'] => milestonesState.milestones.success,
);

export const getPunctualityRate = createSelector(
  getTasksGrouped,
  (tasksGrouped) => {
    if (tasksGrouped.completed && tasksGrouped.completed.length) {
      const rates = tasksGrouped.completed.map(task => parseFloat(task.punctuality_rate || '0'));
      return rates.reduce((a, b) => a + b, 0) / rates.length
    }
    return 0
  }
)

export const getPunctualityPercentage = createSelector(
  getPunctualityRate,
  (punctualityRate) => {
    return (punctualityRate / 5) * 100
  }
)

export const getContentRate = createSelector(
  getTasksGrouped,
  (tasksGrouped) => {
    if (tasksGrouped.completed && tasksGrouped.completed.length) {
      const rates = tasksGrouped.completed.map(task => parseFloat(task.content_rate || '0'));
      return rates.reduce((a, b) => a + b, 0) / rates.length
    }
    return 0
  }
)

export const getContentPercentage = createSelector(
  getContentRate,
  (contentRate) => {
    return (contentRate / 5) * 100
  }
)
