import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call } from "redux-saga/effects";
import {
  signUp,
  signUpRequest,
  signUpSuccess,
  signUpFailure,
} from "../../actions";
import { signUpApi } from "../../api";

export function* signUpSaga(action: ActionType<typeof signUp>): SagaIterator {
  yield put(signUpRequest());
  try {
    const params = action.payload;
    const { errors, status, data } = yield call(signUpApi, params);
    if (errors) {
      yield put(signUpFailure({ errors, status }));
      return;
    }

    yield put(signUpSuccess({ data }));
  } catch (e) {
    yield put(signUpFailure({ errors: e || "Generic error" }));
  }
}
