import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call, delay } from "redux-saga/effects";
import {
  recoverPassword,
  recoverPasswordRequest,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  recoverPasswordInit,
} from "../../actions";
import { recoverPasswordApi } from "../../api";

export function* recoverPasswordSaga(
  action: ActionType<typeof recoverPassword>
): SagaIterator {
  yield put(recoverPasswordRequest());
  try {
    const params = action.payload;
    const { errors, status } = yield call(recoverPasswordApi, params);
    if (errors) {
      yield put(recoverPasswordFailure({ errors, status }));
      return;
    }

    yield put(recoverPasswordSuccess());
  } catch (e) {
    yield put(recoverPasswordFailure({ errors: e || "Generic error" }));
  }
  yield delay(60000); // init store after one minute
  yield put(recoverPasswordInit());
}
