import React, { useMemo } from "react";
import { IonPage, IonCard } from "@ionic/react";
import { Link } from "react-router-dom";
import { RecoverPasswordForm } from "../../components/organisms";
import { Logo, Flex, Spacer } from "../../components/atoms";
import { useAuthentication } from "../../hooks";

const RecoverPassword: React.FC = () => {
  const { recoverPasswordEmailSent } = useAuthentication();

  const ThankYouMessage = useMemo(
    () => (
      <IonCard style={{ padding: 30, textAlign: "center" }}>
        <div>
          <h2>Check your email</h2>
          <p>
            We have just sent you an email with a <b>super secret magic link</b>{" "}
            🧙‍♂️
            <br /> click on it to update your password!
          </p>
        </div>
      </IonCard>
    ),
    []
  );

  return (
    <IonPage>
      <Flex flexDirection="column">
        <Logo width="300px" />
        <h2>Recover Password</h2>
        {recoverPasswordEmailSent ? ThankYouMessage : <RecoverPasswordForm />}
        <Flex height="50px">
          <Link to="./login">Already registered?</Link>
          <Spacer width="10px" />
          <Link to="./sign-up">Create an account</Link>
        </Flex>
      </Flex>
    </IonPage>
  );
};

export default React.memo(RecoverPassword);
