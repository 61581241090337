import { createAction } from "typesafe-actions";
import {
  UpdateProfilePayload,
  UpdateProfileSuccessResponse,
  UpdateProfileFailureResponse,
} from "../../types";
export const updateProfile = createAction("trigger/UPDATE_PROFILE")<
  UpdateProfilePayload
>();
export const updateProfileRequest = createAction(
  "action/UPDATE_PROFILE_REQUEST"
)();
export const updateProfileSuccess = createAction(
  "action/UPDATE_PROFILE_SUCCESS"
)<UpdateProfileSuccessResponse>();
export const updateProfileFailure = createAction(
  "action/UPDATE_PROFILE_FAILURE"
)<UpdateProfileFailureResponse>();
