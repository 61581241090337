import { takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { getNotificationsSaga } from "./getNotificationsSaga";
import { updateNotificationReadSaga } from "./updateNotificationReadSaga";
import { UpdateNotificationReadAction } from "../../types";
import { updateAllNotificationsReadSaga } from "./updateAllNotificationsReadSaga";

export const rootSagas = [
  takeLatest(
    "trigger/GET_NOTIFICATIONS",
    (): SagaIterator => getNotificationsSaga()
  ),

  takeLatest(
    "trigger/UPDATE_NOTIFICATION_READ",
    (action: UpdateNotificationReadAction): SagaIterator =>
      updateNotificationReadSaga(action)
  ),

  takeLatest(
    "trigger/UPDATE_ALL_NOTIFICATIONS_READ",
    (): SagaIterator => updateAllNotificationsReadSaga()
  ),
];
