import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { call, put } from "redux-saga/effects";
import {
  updateProfile,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
} from "../../actions";
import { updateProfileApi } from "../../api";

export function* updateProfileSaga(
  action: ActionType<typeof updateProfile>
): SagaIterator {
  yield put(updateProfileRequest());
  try {
    const params = action.payload;
    const { errors, status, data } = yield call(updateProfileApi, params);
    if (errors) {
      yield put(updateProfileFailure({ errors, status }));
      return;
    }

    yield put(updateProfileSuccess({ data }));
  } catch (e) {
    yield put(updateProfileFailure({ errors: e || "Generic error" }));
  }
}
