import React, { useEffect } from "react";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardTitle,
  IonListHeader,
  IonLabel,
} from "@ionic/react";
import { MilestonesList } from "../../components/organisms";
import { DashboardHeader, TaskHeader } from "../../components/molecule";
import { TaskGroup } from "../../store/types";
import { useHistory } from "react-router";
import { useTask } from "../../hooks/tasks/useTask";

type Props = {
  match: {
    params: { [key: string]: TaskGroup };
  };
};

const TaskScreen: React.FC<Props> = ({ match }) => {
  const history = useHistory();
  const { task_id } = match.params;
  const { task, loaded, loading } = useTask({ task_id });

  useEffect(() => {
    if (loaded && !loading && !task) {
      history.push("/dashboard");
    }
  }, [loaded, history, task, loading]);

  if (!task) {
    return null;
  }

  return (
    <IonPage>
      <DashboardHeader />
      <IonContent className="ion-padding">
        <IonCard className="ion-padding">
          <TaskHeader task={task} />
        </IonCard>
        <IonCard className="ion-padding">
          <IonListHeader>
            <IonLabel>
              <IonCardTitle>Milestones</IonCardTitle>
            </IonLabel>
          </IonListHeader>
          <MilestonesList project_id={task.id} />
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

TaskScreen.displayName = "TaskScreen";

export default React.memo(TaskScreen);
