import { useEffect, useState } from "react";

export const useThemeMode = () => {
  const [prefersDark, setPrefersDark] = useState<string>();
  const mediaMatch = window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

  useEffect(() => {
    if (prefersDark) {
      document.body.classList.remove("dark");
      document.body.classList.remove("light");
      document.body.classList.add(prefersDark || "");
    }
  }, [prefersDark]);

  useEffect(() => {
    setPrefersDark(localStorage.getItem("theme") || mediaMatch);
  }, [mediaMatch]);

  return { prefersDark, setPrefersDark };
};
