import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTasks } from "../../store/actions";
import {
  getTasksList,
  getTasksLoading,
  getTasksLoaded,
  getTasksErrors,
  getTasksGrouped,
} from "../../store/selectors";

export const useTasksList = () => {
  const dispatch = useDispatch();
  const tasks = useSelector(getTasksList);
  const loading = useSelector(getTasksLoading);
  const loaded = useSelector(getTasksLoaded);
  const errors = useSelector(getTasksErrors);
  const tasksGrouped = useSelector(getTasksGrouped);
  const fetchTasks = useCallback(() => dispatch(getTasks()), [dispatch]);

  return {
    tasks,
    loaded,
    errors,
    loading,
    fetchTasks,
    tasksGrouped,
  };
};
