import { fetch } from "../../utils/fetch";
import { MMS_URL } from "../../constants";
import {
  UpdateTaskSuccessResponse,
  UpdateTaskFailureResponse,
  UpdateTaskPayload,
} from "../../types";

type Response = UpdateTaskSuccessResponse | UpdateTaskFailureResponse;

export const updateTasksApi = async (
  payload: UpdateTaskPayload
): Promise<Response> => {
  try {
    const response: Response = await fetch.put({
      url: `${MMS_URL}projects/${payload.project_id}`,
      params: JSON.stringify(payload.data),
    });
    return response;
  } catch (e) {
    return e;
  }
};
