import { takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { getTasksSaga } from "./getTasksSaga";
import { getMilestonesSaga } from "./getMilestonesSaga";
import {
  GetMilestonesAction,
  UpdateTaskAction,
  GetTaskAction,
  GetMilestoneAction,
  UpdateMilestoneAction,
  PluckTaskAction,
  RejectTaskAction,
} from "../../types";
import { updateTaskSaga } from "./updateTaskSaga";
import { getTaskSaga } from "./getTaskSaga";
import { getMilestoneSaga } from "./getMilestoneSaga";
import { updateMilestoneSaga } from "./updateMilestoneSaga";
import { pluckTaskSaga } from "./pluckTaskSaga";
import { rejectTaskSaga } from "./rejectTaskSaga";

export const rootSagas = [
  takeLatest("trigger/GET_TASKS", (): SagaIterator => getTasksSaga()),
  takeLatest(
    "trigger/GET_TASK",
    (action: GetTaskAction): SagaIterator => getTaskSaga(action)
  ),
  takeLatest(
    "trigger/GET_MILESTONES",
    (action: GetMilestonesAction): SagaIterator => getMilestonesSaga(action)
  ),
  takeLatest(
    "trigger/GET_MILESTONE",
    (action: GetMilestoneAction): SagaIterator => getMilestoneSaga(action)
  ),
  takeLatest(
    "trigger/UPDATE_TASK",
    (action: UpdateTaskAction): SagaIterator => updateTaskSaga(action)
  ),
  takeLatest(
    "trigger/PLUCK_TASK",
    (action: PluckTaskAction): SagaIterator => pluckTaskSaga(action)
  ),
  takeLatest(
    "trigger/REJECT_TASK",
    (action: RejectTaskAction): SagaIterator => rejectTaskSaga(action)
  ),
  takeLatest(
    "trigger/UPDATE_MILESTONE",
    (action: UpdateMilestoneAction): SagaIterator => updateMilestoneSaga(action)
  ),
];
