import {
  AuthState,
  ResetPasswordSuccessAction,
  ResetPasswordFailureAction,
  ResetPasswordRequestAction,
  ResetPasswordInitAction,
} from "../../types";

export const resetPasswordRequestCase = (
  state: AuthState,
  _: ResetPasswordRequestAction
): AuthState => ({
  ...state,
  resetPasswordSuccess: false,
  errors: null,
  loading: true,
});

export const resetPasswordSuccessCase = (
  state: AuthState,
  _: ResetPasswordSuccessAction
): AuthState => ({
  ...state,
  resetPasswordSuccess: true,
  errors: null,
  loading: false,
});

export const resetPasswordFailureCase = (
  state: AuthState,
  action: ResetPasswordFailureAction
): AuthState => ({
  ...state,
  resetPasswordSuccess: false,
  errors: action.payload.errors,
  loading: false,
});

export const resetPasswordInitCase = (
  state: AuthState,
  _: ResetPasswordInitAction
): AuthState => ({
  ...state,
  resetPasswordSuccess: false,
  errors: null,
  loading: false,
});
