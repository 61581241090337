import { createAction } from "typesafe-actions";
import {
  SignInPayload,
  SignInSuccessResponse,
  SignInFailureResponse,
} from "../../types";

export const signIn = createAction("trigger/SIGN_IN")<SignInPayload>();
export const signInRequest = createAction("action/SIGN_IN_REQUEST")();
export const signInSuccess = createAction("action/SIGN_IN_SUCCESS")<
  SignInSuccessResponse
>();
export const signInFailure = createAction("action/SIGN_IN_FAILURE")<
  SignInFailureResponse
>();
