import {
  AuthState,
  SignUpSuccessAction,
  SignUpFailureAction,
  SignUpRequestAction,
} from "../../types";

export const signUpRequestCase = (
  state: AuthState,
  _: SignUpRequestAction
): AuthState => ({
  ...state,
  loading: true,
});

export const signUpSuccessCase = (
  state: AuthState,
  action: SignUpSuccessAction
): AuthState => ({
  ...state,
  user: action.payload.data,
  loading: false,
});

export const signUpFailureCase = (
  state: AuthState,
  action: SignUpFailureAction
): AuthState => ({
  ...state,
  loading: false,
  errors: action.payload.errors,
});
