import { createAction } from "typesafe-actions";
import { RefreshTokenPayload } from "../../types";

export const refreshToken = createAction("trigger/REFRESH_TOKEN")<
  RefreshTokenPayload
>();
export const refreshTokenRequest = createAction(
  "action/REFRESH_TOKEN_REQUEST"
)();
export const refreshTokenSuccess = createAction(
  "action/REFRESH_TOKEN_SUCCESS"
)();
export const refreshTokenFailure = createAction(
  "action/REFRESH_TOKEN_FAILURE"
)();
