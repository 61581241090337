import React from "react";
import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { warning } from "ionicons/icons";

type Props = {
  error: string;
};

const ErrorInline: React.FC<Props> = ({ error }) => {
  return (
    <IonChip color="danger">
      <IonIcon icon={warning} />
      <IonLabel>
        <div dangerouslySetInnerHTML={{ __html: error }} />
      </IonLabel>
    </IonChip>
  );
};

ErrorInline.displayName = "ErrorInline";

export default React.memo(ErrorInline);
