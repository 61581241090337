import React from "react";
import { IonPage, IonList, IonItem, IonContent } from "@ionic/react";
import { ThemeToggle } from "../../components/organisms";
import { DashboardHeader } from "../../components/molecule";

const SettingsScreen: React.FC = () => {
  return (
    <IonPage>
      <DashboardHeader />
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <ThemeToggle /> Enable dark mode
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(SettingsScreen);
