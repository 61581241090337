import React from "react";
import styled from "styled-components";

const Hidden = styled.div`
  display: none;
`;

Hidden.displayName = "Hidden";

export default React.memo(Hidden);
