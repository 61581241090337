import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMilestones } from "../../store/actions";
import {
  getMilestonesList,
  getMilestonesLoading,
  getMilestonesLoaded,
  getMilestonesErrors,
} from "../../store/selectors";

export const useMilestonesList = () => {
  const dispatch = useDispatch();
  const milestones = useSelector(getMilestonesList);
  const loading = useSelector(getMilestonesLoading);
  const loaded = useSelector(getMilestonesLoaded);
  const errors = useSelector(getMilestonesErrors);
  const fetchMilestones = useCallback(
    ({ project_id }) => dispatch(getMilestones({ project_id })),
    [dispatch]
  );

  return {
    milestones,
    loaded,
    errors,
    loading,
    fetchMilestones,
  };
};
