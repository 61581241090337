import React from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { useAuthentication } from "../../../hooks";

type Props = RouteProps & {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
  authenticated?: boolean;
};

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  authenticated = true,
  ...rest
}) => {
  const { isAuthenticated } = useAuthentication();
  const condition = authenticated ? isAuthenticated : !isAuthenticated;
  const redirect = authenticated ? "/login" : "/dashboard";
  return (
    <Route
      {...rest}
      render={(props) =>
        condition ? <Component {...props} /> : <Redirect to={redirect} />
      }
    />
  );
};

export default PrivateRoute;
