// Side effects Services
export const getAuthToken = () => {
  return localStorage.getItem("id_token");
};

export const setAuthToken = (token: string) => {
  localStorage.setItem("id_token", token);
};

export const removeAuthToken = () => {
  return localStorage.removeItem("id_token");
};

export const getExpirationDate = () => {
  return localStorage.getItem("exp");
};

export const setExpirationDate = (exp: string) => {
  return localStorage.setItem("exp", exp);
};

export const removeExpirationDate = () => {
  return localStorage.removeItem("exp");
};
