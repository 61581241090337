import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import {
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFailure,
} from "../../actions";
import { getNotificationsApi } from "../../api";

export function* getNotificationsSaga(): SagaIterator {
  yield put(getNotificationsRequest());
  try {
    const { errors, status, ...payload } = yield call(getNotificationsApi);
    if (errors || !payload.data) {
      yield put(getNotificationsFailure({ errors, status }));
      return;
    }
    yield put(getNotificationsSuccess(payload.data));
  } catch (e) {
    yield put(getNotificationsFailure({ errors: e || "Generic error" }));
  }
}
