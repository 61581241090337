import { useSelector } from "react-redux";
import {
  getPunctualityPercentage,
  getPunctualityRate,
  getContentRate,
  getContentPercentage,
} from "../../store/selectors";
import { useTasksList } from ".";

export const useTasksRates = () => {
  const contentRate = useSelector(getContentRate);
  const punctualityRate = useSelector(getPunctualityRate);
  const contentPercentage = useSelector(getContentPercentage);
  const punctualityPercentage = useSelector(getPunctualityPercentage);
  const generalRate = (punctualityPercentage + contentPercentage) / 2;
  const {
    tasksGrouped: {
      completed: { length: completedTasksCount },
    },
  } = useTasksList();

  return {
    generalRate,
    contentRate,
    punctualityRate,
    contentPercentage,
    punctualityPercentage,
    completedTasksCount,
  };
};
