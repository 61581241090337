import { fetch } from "../../utils/fetch";
import { MMS_URL } from "../../constants";
import {
  GetMilestonesSuccessResponse,
  GetMilestonesFailureResponse,
  GetMilestonesPayload,
} from "../../types";

type Response = GetMilestonesSuccessResponse | GetMilestonesFailureResponse;

export const getMilestonesApi = async (
  payload: GetMilestonesPayload
): Promise<Response> => {
  try {
    const response: Response = await fetch.get({
      url: `${MMS_URL}/projects/${payload.project_id}/milestones`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
