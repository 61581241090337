import {
  AuthState,
  RefreshTokenSuccessAction,
  RefreshTokenFailureAction,
  RefreshTokenRequestAction,
} from "../../types";

export const refreshTokenRequestCase = (
  state: AuthState,
  _: RefreshTokenRequestAction
): AuthState => ({
  ...state,
  loading: true,
});

export const refreshTokenSuccessCase = (
  state: AuthState,
  _: RefreshTokenSuccessAction
): AuthState => ({
  ...state,
  loading: false,
});

export const refreshTokenFailureCase = (
  state: AuthState,
  _: RefreshTokenFailureAction
): AuthState => ({
  ...state,
  loading: false,
});
