import {
  TasksState,
  GetTaskSuccessAction,
  GetTaskFailureAction,
  GetTaskRequestAction,
} from "../../types";

export const getTaskRequestCase = (
  state: TasksState,
  _: GetTaskRequestAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    loading: true,
  },
});

export const getTaskSuccessCase = (
  state: TasksState,
  action: GetTaskSuccessAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    data: action.payload.data,
    loading: false,
    loaded: true,
  },
});

export const getTaskFailureCase = (
  state: TasksState,
  action: GetTaskFailureAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    errors: action.payload.errors,
    loading: false,
    loaded: true,
  },
});
