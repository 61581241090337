import React, { useMemo, useCallback, useEffect, useState } from "react";
import { IonButton, IonSpinner } from "@ionic/react";
import { Tooltip } from "@material-ui/core";
import { useMilestone } from "../../../hooks";
import { ErrorInline } from "../../molecule";
import { Spacer } from "../../atoms";
import { Milestone } from "../../../store/types";

type Props = {
  milestone_id: string;
  disableSubmission?: string | null;
  data?: Partial<Milestone>;
};

const MilestoneActions: React.FC<Props> = ({
  milestone_id,
  disableSubmission,
  data = {},
}) => {
  const [saved, setSaved] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [commentAdded, setCommentAdded] = useState(false);

  const {
    loading,
    updateMilestone,
    error: milestoneErrors,
    success,
  } = useMilestone({
    milestone_id,
  });

  const milestoneError = useMemo(
    () => (milestoneErrors && milestoneErrors[milestone_id]) || null,
    [milestoneErrors, milestone_id]
  );

  const handleSave = useCallback(
    () =>
      updateMilestone({
        milestone_id,
        data,
      }),
    [data, milestone_id, updateMilestone]
  );

  const handleSubmit = useCallback(
    () =>
      updateMilestone({
        milestone_id,
        data: {
          ...data,
          status: "Completed",
        },
        verifyContent: true,
      }),
    [data, milestone_id, updateMilestone]
  );

  useEffect(() => {
    if (data.content) {
      document.removeEventListener("conversation_created", () =>
        setTimeout(() => setCommentAdded(true), 500)
      );

      document.addEventListener("conversation_created", () =>
        setTimeout(() => setCommentAdded(true), 500)
      );
      return () =>
        document.removeEventListener("conversation_created", () =>
          setTimeout(() => setCommentAdded(true), 500)
        );
    }
  }, [data.content]);

  useEffect(() => {
    console.log(data.status, success);
    if (success) {
      setSaved(true);
    }
    if (success && data.status === "Completed") {
      setSubmitted(true);
    }
  }, [success, data.status]);

  useEffect(() => {
    if (data.content) {
      setSaved(false);
      setSubmitted(false);
    }
  }, [data.content]);

  useEffect(() => {
    if (commentAdded && data.content) {
      updateMilestone({ milestone_id, data: { content: data.content } });
      setCommentAdded(false);
    }
  }, [commentAdded, data.content, milestone_id, updateMilestone]);

  return (
    <>
      {milestoneError && <ErrorInline error={milestoneError} />}
      <Spacer width="20px" />
      <Tooltip
        title={disableSubmission || ""}
        disableHoverListener={!disableSubmission}
      >
        <div>
          <IonButton
            fill="solid"
            color={submitted ? "success" : "primary"}
            onClick={handleSubmit}
            disabled={!!disableSubmission}
          >
            {submitted ? (
              "Submitted"
            ) : (
              <>{loading ? <IonSpinner name="dots" /> : "Send for review"}</>
            )}
          </IonButton>
        </div>
      </Tooltip>
      <IonButton
        fill="solid"
        color={saved ? "success" : "primary"}
        onClick={handleSave}
      >
        {saved ? "Saved" : <>{loading ? <IonSpinner name="dots" /> : "Save"}</>}
      </IonButton>
    </>
  );
};

MilestoneActions.displayName = "MilestoneActions";

export default React.memo(MilestoneActions);
