import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { capitalize } from "../../../store/utils/ui";

type Type =
  | "number"
  | "time"
  | "text"
  | "date"
  | "email"
  | "password"
  | "search"
  | "tel"
  | "url";

type Option = { id: string; name: string };

type Props = {
  name: string;
  type?: Type;
  label?: string;
  placeholder?: string;
  required?: boolean;
  multiple?: boolean;
  control: any;
  options?: Option[];
  rules?: object;
};

const FormInput: React.FC<Props> = ({
  name,
  label,
  required,
  type = "text",
  options,
  multiple,
  placeholder,
  control,
  rules,
}) => {
  const inputLabel = label || capitalize(name).split("_").join(" ");
  const inputGroup = useMemo(() => {
    if (options && options.length) {
      return (
        <IonSelect multiple={multiple} placeholder={placeholder}>
          {options.map((option) => (
            <IonSelectOption key={option.id + option.name} value={option.id}>
              {option.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      );
    }
    return <IonInput style={{ width: "100%" }} />;
  }, [options, placeholder, multiple]);
  return (
    <IonItem style={{ padding: 0 }} className="ion-no-padding">
      <IonLabel style={{ paddingLeft: 5 }}>{inputLabel}</IonLabel>
      <Controller
        as={inputGroup}
        onChange={([selected]: any) => {
          return selected.detail.value;
        }}
        onChangeName="onIonChange"
        name={name}
        control={control}
        type={type}
        rules={{
          ...rules,
          ...(required && {
            required: `${inputLabel} is required`,
          }),
        }}
      />
    </IonItem>
  );
};

FormInput.displayName = "FormInput";

export default React.memo(FormInput);
