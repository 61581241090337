import { createAction } from "typesafe-actions";
import {
  UpdateNotificationReadPayload,
  UpdateNotificationReadFailureResponse,
  UpdateNotificationReadSuccessResponse,
} from "../../types";

export const updateNotificationRead = createAction(
  "trigger/UPDATE_NOTIFICATION_READ"
)<UpdateNotificationReadPayload>();
export const updateNotificationReadRequest = createAction(
  "action/UPDATE_NOTIFICATION_READ_REQUEST"
)();
export const updateNotificationReadSuccess = createAction(
  "action/UPDATE_NOTIFICATION_READ_SUCCESS"
)<UpdateNotificationReadSuccessResponse['data']>();
export const updateNotificationReadFailure = createAction(
  "action/UPDATE_NOTIFICATION_READ_FAILURE"
)<UpdateNotificationReadFailureResponse>();
