import React from "react";
import styled from "styled-components";

type Props = {
  flexDirection?: "row" | "column";
  justifyContent?: "start" | "end" | "center";
  alignContent?: "start" | "end" | "center";
  alignItems?: "start" | "end" | "center";
  height?: string;
  width?: string;
};

const Flex = styled.div<Props>`
  display: flex;
  height: ${({ height = "100%" }) => height};
  width: ${({ width = "100%" }) => width};
  flex-direction: ${({ flexDirection = "row" }) => flexDirection};
  justify-content: ${({ justifyContent = "center" }) => justifyContent};
  align-content: ${({ alignContent = "center" }) => alignContent};
  align-items: ${({ alignItems = "center" }) => alignItems};
`;

Flex.displayName = "Flex";

export default React.memo(Flex);
