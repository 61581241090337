import React, { useEffect } from "react";
import shortid from "shortid";
import TaskOverviewSection from "./TaskOverviewSection";
import {
  construct,
  addOutline,
  checkmarkDone,
  hourglassOutline,
} from "ionicons/icons";
import { useTasksList } from "../../../hooks/tasks/useTasksList";
import { ErrorToast } from "../../molecule";
import { TaskGroup } from "../../../store/types";
import { IonSpinner } from "@ionic/react";
import { Flex } from "../../atoms";

type Props = {
  section?: TaskGroup;
};

type Section = {
  id: TaskGroup;
  label: string;
  icon: string;
};

const tasksGroup: Section[] = [
  { id: "over_due", label: "Over due", icon: hourglassOutline },
  { id: "in_progress", label: "In progress", icon: construct },
  { id: "new_projects", label: "New projects", icon: addOutline },
  { id: "completed", label: "Completed", icon: checkmarkDone },
];

const TasksOverview: React.FC<Props> = ({ section }) => {
  const { fetchTasks, errors, loading } = useTasksList();
  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);
  const sections = tasksGroup.find((group) => group.id === section)
    ? tasksGroup.filter((group) => group.id === section)
    : tasksGroup;

  if (loading) {
    return (
      <Flex>
        <IonSpinner name="bubbles" />
      </Flex>
    );
  }
  return (
    <>
      {sections.map((section) => (
        <TaskOverviewSection
          key={shortid.generate()}
          section={section.id}
          label={section.label}
          icon={section.icon}
          isOverview={sections.length > 1}
        />
      ))}
      <ErrorToast message={errors} />
    </>
  );
};

TasksOverview.displayName = "TasksOverview";

export default React.memo(TasksOverview);
