import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call, select } from "redux-saga/effects";
import { xml2json } from "xml-js";
import { updateMilestoneApi } from "../../api/tasks/updateMilestone";
import { copyScapeVerifyMilestoneApi } from "../../api/tasks/copyscapeVerifyMilestone";
import {
  updateMilestone,
  updateMilestoneRequest,
  updateMilestoneSuccess,
  updateMilestoneFailure,
} from "../../actions";
import { getMilestonesList } from "../../selectors";
import { Milestone } from "../../types";

export function* updateMilestoneSaga(
  action: ActionType<typeof updateMilestone>
): SagaIterator {
  const params = action.payload;
  yield put(updateMilestoneRequest());
  try {
    const milestones = yield select(getMilestonesList);
    if (
      params.data.content &&
      params.verifyContent
      // process.env.NODE_ENV !== "development"
    ) {
      try {
        const xmlResponse = yield call(
          copyScapeVerifyMilestoneApi,
          params.data.content
        );
        const textResponse = yield xmlResponse.text();
        const jsonResponse = yield JSON.parse(
          xml2json(textResponse, { compact: true })
        );
        const copiesFound = parseInt(
          jsonResponse?.response?.count?._text ?? "0"
        );
        const checkedWords = parseInt(
          jsonResponse?.response?.querywords?._text ?? "0"
        );
        const minMatchedWords = parseInt(
          jsonResponse?.response?.result[0]
            ? jsonResponse?.response?.result[0].minwordsmatched?._text ?? "0"
            : "0"
        );
        const copyPercantage = (minMatchedWords / checkedWords) * 100;
        const allViewUrl = jsonResponse?.response?.allviewurl?._text;

        if (copiesFound > 0 && copyPercantage > 10) {
          yield put(
            updateMilestoneFailure({
              errors: {
                [params.milestone_id]: `Your content didn't pass copyscape verification, <a target='_blank' style="color: inherit" href="${allViewUrl}">more details</a>`,
              },
            })
          );
          return;
        }
      } catch (e) {
        console.log("err in copyscape", e);
      }
    }
    const { errors, status, data } = yield call(updateMilestoneApi, params);
    if (errors || !data || !data.id) {
      yield put(updateMilestoneFailure({ errors, status }));
      return;
    }

    const newMilestones = milestones.filter(
      (milestone: Milestone) => milestone.id !== data.id
    );
    yield put(updateMilestoneSuccess([...newMilestones, data]));
  } catch (e) {
    console.error(e);
    yield put(
      updateMilestoneFailure({
        errors: {
          [params.milestone_id]: e || "Generic error",
        },
      })
    );
  }
}
