import {
  MilestonesState,
  GetMilestoneSuccessAction,
  GetMilestoneFailureAction,
  GetMilestoneRequestAction,
} from "../../types";

export const getMilestoneRequestCase = (
  state: MilestonesState,
  _: GetMilestoneRequestAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    loaded: false,
    data: [],
    loading: true,
  },
});

export const getMilestoneSuccessCase = (
  state: MilestonesState,
  action: GetMilestoneSuccessAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    data: action.payload.data,
    loading: false,
    loaded: true,
  },
});

export const getMilestoneFailureCase = (
  state: MilestonesState,
  action: GetMilestoneFailureAction
): MilestonesState => ({
  ...state,
  milestones: {
    ...state.milestones,
    errors: action.payload.errors,
    loading: false,
    loaded: true,
  },
});
