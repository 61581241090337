import React, { useCallback, useEffect, useState } from "react";
import {
  ListItem,
  ListItemAvatar,
  Badge,
  Avatar,
  ListItemText,
  Divider,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Notification as NotificationProps } from "../../../store/types";
import { useNotifications } from "../../../hooks/notifications/useNotifications";
import {
  NotificationImportant,
  Warning,
  Info,
  ExpandLess,
  ExpandMore,
  OpenInNew,
} from "@material-ui/icons";
import { useHistory } from "react-router";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/relativeTime"));
dayjs.extend(require('dayjs/plugin/utc'));

type Props = {
  notification: NotificationProps;
};

const useStyles = makeStyles({
  message: {
    display: "block",
    overflow: "hidden",
    "& > p": {
      margin: 0,
    },
  },
  badgeRoot: {
    flexGrow: 1,
    maxWidth: 180,
  },
  badgeBadge: {
    fontSize: 10,
    transform: "scale(1) translate(50%, -40%)",
  },
  readMore: {
    // color: "#1212b3",
    fontSize: 12,
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "none",
  },
  readMoreIcon: {
    width: 14,
    height: 14,
    marginBottom: -3,
  },
});

const Notification: React.FC<Props> = ({ notification }) => {
  const history = useHistory();
  const [canExpand, setCanExpand] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [rawText, setRawText] = useState<string>('');
  const toggleExpand = useCallback((e) => {
    e.stopPropagation();
    setExpanded((e) => !e);
  }, []);
  const classes = useStyles();
  const { updateNotificationStatus, loading } = useNotifications();
  const icons = {
    ALERT: <NotificationImportant />,
    WARNING: <Warning />,
    INFO: <Info />,
  };

  const handleToggleRead = useCallback(
    (e, notification) => {
      e.stopPropagation();
      if (!loading) {
        updateNotificationStatus({
          notification_id: notification.id,
          read: notification.read ? 0 : 1,
        });
      }
    },
    [loading, updateNotificationStatus]
  );

  const handleNotificationAction = useCallback(() => {
    if (notification.link) {
      const isExternal = notification.link.indexOf("http") === 0;
      const path = notification.link.split("#")[1];
      if (isExternal) {
        window.open(notification.link);
        return;
      }
      if (path) {
        history.push(path);
        return;
      }
      window.open(notification.link);
      return;
    }
  }, [notification, history]);

  useEffect(() => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = notification.message;
    const text = tmp.textContent || tmp.innerText || "";
    setCanExpand(text.split(' ').length > 9);
    setRawText(text.split(' ').length > 9 ? text.split(' ').slice(0, 9).join(' ') + '...' : text)
  }, [notification.message])

  return (
    <div>
      <ListItem
        // @ts-ignore
        button={false}
      >
        <div
          style={{
            minWidth: 20,
            height: 20,
            background: !notification.read ? "#fec502" : "transparent",
            border: "1px solid #3bb777",
            borderRadius: "100%",
            marginRight: 10,
          }}
          onClick={(e) => handleToggleRead(e, notification)}
        />
        <ListItemAvatar>
          <Badge
            overlap="circle"
            variant="dot"
            color="error"
            invisible={!!notification.read}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Avatar color="primary">{icons[notification.type]}</Avatar>
          </Badge>
        </ListItemAvatar>
        <Badge
          color="default"
          invisible={!notification?.created_at}
          classes={{ root: classes.badgeRoot, badge: classes.badgeBadge }}
          badgeContent={(dayjs as any).utc(notification?.created_at).local().fromNow()}
        >
          <ListItemText
            color="primary"
            primary={notification.title}
            secondary={
              <>
                <span
                  className={classes.message}
                  dangerouslySetInnerHTML={{ __html: expanded ? notification.message : rawText }}
                />
                {notification.link && <a href={notification.link} className={classes.readMore} onClick={handleNotificationAction}>More details <OpenInNew className={classes.readMoreIcon} /></a>}
              </>
            }
          />
        </Badge>
        {canExpand &&
          <>
            {expanded ? (
              <IconButton onClick={toggleExpand}>
                <ExpandLess />
              </IconButton>
            ) : (
                <IconButton onClick={toggleExpand}>
                  <ExpandMore />
                </IconButton>
              )}
          </>
        }
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  );
};

Notification.displayName = "Notification";

export default React.memo(Notification);
