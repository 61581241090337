import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SignInPayload,
  SignUpPayload,
  RecoverPasswordPayload,
  ResetPasswordPayload,
  UpdateProfilePayload,
} from "../../store/types";
import { getAuthToken, getExpirationDate } from "../../store/utils/storage";
import {
  getAuthErrors,
  getSignInIsLoading,
  getUserInfo as getUserInfoSelector,
  getIsRecoverEmailSent,
  getIsResetPasswordSuccess,
} from "../../store/selectors";
import {
  signIn,
  signOut,
  signUp,
  updateProfile as updateProfileAction,
  getUserInfo as getUserInfoAction,
  verifyToken as verifyTokenAction,
  resetPassword as resetPasswordAction,
  recoverPassword as recoverPasswordAction,
} from "../../store/actions";

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUserInfoSelector);
  const errors = useSelector(getAuthErrors);
  const loading = useSelector(getSignInIsLoading);
  const recoverPasswordEmailSent = useSelector(getIsRecoverEmailSent);
  const resetPasswordSuccess = useSelector(getIsResetPasswordSuccess);
  const tokenExpired =
    parseInt(getExpirationDate() || "0") < new Date().getTime() * 1000;
  const hasValidToken = getAuthToken() && getExpirationDate() && tokenExpired;

  const isAuthenticated = hasValidToken;

  const login = useCallback(
    (params: SignInPayload) => dispatch(signIn(params)),
    [dispatch]
  );

  const recoverPassword = useCallback(
    (params: RecoverPasswordPayload) => dispatch(recoverPasswordAction(params)),
    [dispatch]
  );

  const resetPassword = useCallback(
    (params: ResetPasswordPayload) => dispatch(resetPasswordAction(params)),
    [dispatch]
  );

  const verifyToken = useCallback(() => dispatch(verifyTokenAction()), [
    dispatch,
  ]);

  const register = useCallback(
    (params: SignUpPayload) => dispatch(signUp(params)),
    [dispatch]
  );

  const updateProfile = useCallback(
    (params: UpdateProfilePayload) => dispatch(updateProfileAction(params)),
    [dispatch]
  );

  const logout = useCallback(() => dispatch(signOut()), [dispatch]);

  const getUserInfo = useCallback(() => dispatch(getUserInfoAction()), [
    dispatch,
  ]);

  return {
    user,
    login,
    logout,
    errors,
    loading,
    register,
    getUserInfo,
    verifyToken,
    hasValidToken,
    updateProfile,
    resetPassword,
    recoverPassword,
    isAuthenticated,
    resetPasswordSuccess,
    recoverPasswordEmailSent,
  };
};
