import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { TasksNavigation, TasksOverview } from "../../components/organisms";
import { DashboardHeader } from "../../components/molecule";
import { TabBar } from "../../components/atoms";
import { useMediaQuery } from "../../hooks";
import { TaskGroup } from "../../store/types";

type Props = {
  match: {
    params: { [key: string]: TaskGroup };
  };
};

const TasksScreen: React.FC<Props> = ({ match }) => {
  const { isMobile } = useMediaQuery();
  const { tasks_group } = match.params;

  return (
    <IonPage>
      <DashboardHeader />
      <IonContent className="ion-padding">
        <TasksOverview section={tasks_group} />
        {isMobile && (
          <TabBar>
            <TasksNavigation />
          </TabBar>
        )}
      </IonContent>
    </IonPage>
  );
};

TasksScreen.displayName = "TasksScreen";

export default React.memo(TasksScreen);
