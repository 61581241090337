import React, { useEffect, useCallback } from "react";
import { IonList, IonItem, IonSkeletonText, IonLabel } from "@ionic/react";
import { Milestone } from "../../molecule";
import { useMilestonesList } from "../../../hooks/tasks/useMilestonesList";
import { Flex } from "../../atoms";
import { useTask } from "../../../hooks/tasks/useTask";
import { useAuthentication } from "../../../hooks";
import { useHistory } from "react-router";

type Props = {
  project_id: string;
};

const MilestonesList: React.FC<Props> = ({ project_id }) => {
  const { milestones, loaded, loading, fetchMilestones } = useMilestonesList();
  const history = useHistory();
  const { user } = useAuthentication();
  const { task } = useTask({ task_id: project_id });

  useEffect(() => {
    fetchMilestones({ project_id });
  }, [project_id, fetchMilestones]);

  const goToMilestone = useCallback(
    (milestone_id) => history.push(`/milestone/${milestone_id}`),
    [history]
  );

  if (loading) {
    return (
      <IonList>
        {new Array(5).fill(true).map((_, i) => (
          <IonItem detail key={i}>
            <IonLabel>
              <h2>
                <IonSkeletonText animated style={{ width: 100 }} />
              </h2>
              <ul>
                <li>
                  <IonSkeletonText animated style={{ width: 200 }} />
                </li>
                <li>
                  <IonSkeletonText animated style={{ width: 150 }} />
                </li>
                <li>
                  <IonSkeletonText animated style={{ width: 80 }} />
                </li>
              </ul>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    );
  }

  if (loaded && !milestones) {
    return (
      <Flex>
        <h3 style={{ color: "var(--ion-color-light)", textAlign: "center" }}>
          Nothing to show here
        </h3>
      </Flex>
    );
  }

  return (
    <>
      <IonList>
        {milestones.map((milestone) => (
          <IonItem
            button
            detail
            onClick={() => goToMilestone(milestone.id)}
            disabled={task?.plucked_by !== user?.id}
            key={milestone.id}
          >
            <Milestone milestone={milestone} />
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

MilestonesList.displayName = "MilestonesList";

export default React.memo(MilestonesList);
