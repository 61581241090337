import React, { useMemo } from "react";

type Props = {
  count: number;
  rate: number;
};

const MangoAvatar: React.FC<Props> = ({ count, rate }) => {
  const age = useMemo(() => (count < 2 ? "baby" : "parent"), [count]);
  const feel = useMemo(() => {
    if (rate === 0) {
      return "happy";
    }
    if (rate < 25) {
      return "mad";
    }
    if (rate < 50) {
      return "sad";
    }
    if (rate < 75) {
      return "happy";
    }
    return "excited";
  }, [rate]);
  const mangoImg = useMemo(
    () => require(`../../../assets/img/mango_${age}_${feel}.png`),
    [age, feel]
  );
  return <img src={mangoImg} width="180" alt="Your mango" />;
};

MangoAvatar.displayName = "MangoAvatar";

export default React.memo(MangoAvatar);
