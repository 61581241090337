import {
  AuthState,
  RecoverPasswordSuccessAction,
  RecoverPasswordFailureAction,
  RecoverPasswordRequestAction,
  RecoverPasswordInitAction,
} from "../../types";

export const recoverPasswordRequestCase = (
  state: AuthState,
  _: RecoverPasswordRequestAction
): AuthState => ({
  ...state,
  recoverPasswordEmailSent: false,
  errors: null,
  loading: true,
});

export const recoverPasswordSuccessCase = (
  state: AuthState,
  _: RecoverPasswordSuccessAction
): AuthState => ({
  ...state,
  errors: null,
  recoverPasswordEmailSent: true,
  loading: false,
});

export const recoverPasswordFailureCase = (
  state: AuthState,
  action: RecoverPasswordFailureAction
): AuthState => ({
  ...state,
  loading: false,
  recoverPasswordEmailSent: false,
  errors: action.payload.errors,
});

export const recoverPasswordInitCase = (
  state: AuthState,
  _: RecoverPasswordInitAction
): AuthState => ({
  ...state,
  loading: false,
  recoverPasswordEmailSent: false,
  errors: null,
});
