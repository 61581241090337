import { fetch } from "../../utils/fetch";
import { AUTH_URL } from "../../constants";
import {
  SignInPayload,
  SignInSuccessResponse,
  SignInFailureResponse,
} from "../../types";

type Response = SignInFailureResponse | SignInSuccessResponse;

export const signInApi = async (payload: SignInPayload): Promise<Response> => {
  let formData = new FormData();
  formData.append("email", payload.email);
  formData.append("password", payload.password);
  try {
    const response: Response = await fetch.post({
      url: `${AUTH_URL}auth/login`,
      params: formData,
      formData: true,
    });
    return response;
  } catch (e) {
    return e;
  }
};
