import React, { useCallback } from "react";
import { IonButton } from "@ionic/react";
import { useTask, useAuthentication } from "../../../hooks";

type Props = {
  task_id: string;
};

const TaskActions: React.FC<Props> = ({ task_id }) => {
  const { task, rejectTask, pluckTask } = useTask({ task_id });
  const { user } = useAuthentication();
  const isPlucked = task?.status === "IN_PROGRESS";
  const isRejected =
    task?.rejected_by && user && task?.rejected_by.includes(user?.id);

  const togglePluckTask = useCallback(() => {
    pluckTask({
      project_id: task_id,
      unpluck: isPlucked,
    });
  }, [pluckTask, task_id, isPlucked]);

  const toggleRejectTask = useCallback(() => {
    rejectTask({
      project_id: task_id,
      unreject: !!isRejected,
    });
  }, [rejectTask, task_id, isRejected]);

  return (
    <div>
      {task?.status !== "APPROVED" && (
        <>
          {!isRejected && (
            <IonButton
              fill={!isPlucked ? "solid" : "outline"}
              color={!isPlucked ? "primary" : "danger"}
              onClick={togglePluckTask}
            >
              {!isPlucked ? "Pluck" : "Unpluck"}
            </IonButton>
          )}
          {!isPlucked && (
            <IonButton
              fill={!isRejected ? "solid" : "outline"}
              color="danger"
              onClick={toggleRejectTask}
            >
              {!isRejected ? "Reject" : "Unreject"}
            </IonButton>
          )}
        </>
      )}
    </div>
  );
};

TaskActions.displayName = "TaskActions";

export default React.memo(TaskActions);
