import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useAuthentication } from "../../../hooks";
import { Spacer, FormErrors, FormSubmit } from "../../atoms";
import { FormInput, ErrorToast } from "../../molecule";
import { IonProgressBar } from "@ionic/react";
import { scorePassword } from "../../../store/utils/ui";

type Props = {
  token: string;
};

const ResetPasswordForm: React.FC<Props> = ({ token }) => {
  const { handleSubmit, errors, control, watch } = useForm();
  const { resetPassword, errors: authErrors, loading } = useAuthentication();
  const cutOff = process.env.NODE_ENV === "production" ? 80 : 8;
  const validatePassword = useCallback(
    (password: string) => {
      if (password.length < 10 || password.length >= 15) {
        return "Password has to be between 10 and 15 characters";
      }
      if (scorePassword(password) < cutOff) {
        return "Password is weak, try adding special characters, numbers and letters";
      }
      return password === watch("new_password") || "Password doesn't match";
    },
    [watch, cutOff]
  );

  const onSubmit = useCallback(
    (values: any) => {
      resetPassword({
        token,
        newPassword: values.new_password,
        verifyPassword: values.verify_password,
      });
    },
    [resetPassword, token]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Spacer height="20px" />
      <FormInput
        name="new_password"
        type="password"
        control={control}
        required
      />
      <FormInput
        name="verify_password"
        type="password"
        control={control}
        rules={{
          validate: (value: any) => {
            return validatePassword(value);
          },
        }}
        required
      />
      <Spacer height="20px" />
      <IonProgressBar
        value={scorePassword(watch("new_password")) / 100}
        color={
          scorePassword(watch("new_password")) > cutOff
            ? "success"
            : scorePassword(watch("new_password")) > cutOff / 2
              ? "warning"
              : "danger"
        }
      />
      {errors && <FormErrors errors={Object.values(errors)} />}
      <Spacer height="20px" />
      <FormSubmit label="Reset password" loading={loading} />
      <ErrorToast message={authErrors} />
    </form>
  );
};

ResetPasswordForm.displayName = "ResetPasswordForm";

export default React.memo(ResetPasswordForm);
