import { fetch } from "../../utils/fetch";
import { AUTH_URL } from "../../constants";
import {
  GetUserInfoFailureResponse,
  GetUserInfoSuccessResponse,
} from "../../types";

type Response = GetUserInfoFailureResponse | GetUserInfoSuccessResponse;

export const userInfoApi = async (): Promise<Response> => {
  try {
    const response: GetUserInfoSuccessResponse = await fetch.get({
      url: `${AUTH_URL}users/me`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
