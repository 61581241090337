import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTask as updateTaskAction,
  pluckTask as pluckTaskAction,
  rejectTask as rejectTaskAction,
  getTask,
} from "../../store/actions";
import {
  getTasksList,
  getTasksLoaded,
  getTasksLoading,
} from "../../store/selectors";
import {
  PluckTaskPayload,
  RejectTaskPayload,
  UpdateTaskPayload,
} from "../../store/types";

export const useTask = ({ task_id }: { task_id: string }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const tasks = useSelector(getTasksList);
  const loaded = useSelector(getTasksLoaded);
  const loading = useSelector(getTasksLoading);
  const task = tasks.find((task) => task.id === task_id);
  const fetchTask = useCallback(() => dispatch(getTask({ task_id })), [
    dispatch,
    task_id,
  ]);
  const updateTask = useCallback(
    (params: UpdateTaskPayload) => dispatch(updateTaskAction(params)),
    [dispatch]
  );
  const pluckTask = useCallback(
    (params: PluckTaskPayload) => dispatch(pluckTaskAction(params)),
    [dispatch]
  );
  const rejectTask = useCallback(
    (params: RejectTaskPayload) => dispatch(rejectTaskAction(params)),
    [dispatch]
  );

  useEffect(() => {
    if (!loaded || (loaded && !task && !fetched)) {
      fetchTask();
      setFetched(true);
    }
  }, [loaded, fetchTask, fetched, task]);

  return {
    task,
    loaded,
    loading,
    pluckTask,
    rejectTask,
    updateTask,
  };
};
