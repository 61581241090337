import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call, delay } from "redux-saga/effects";
import { resetPasswordApi } from "../../api";
import {
  resetPassword,
  resetPasswordRequest,
  resetPasswordFailure,
  resetPasswordSuccess,
  resetPasswordInit,
} from "../../actions";

export function* resetPasswordSaga(
  action: ActionType<typeof resetPassword>
): SagaIterator {
  yield put(resetPasswordRequest());
  try {
    const params = action.payload;
    const { errors, status } = yield call(resetPasswordApi, params);
    if (errors) {
      yield put(resetPasswordFailure({ errors, status }));
      return;
    }

    yield put(resetPasswordSuccess());
  } catch (e) {
    yield put(resetPasswordFailure({ errors: e || "Generic error" }));
  }
  yield delay(60000); // init store after one minute
  yield put(resetPasswordInit());
}
