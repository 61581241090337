import { combineReducers } from "redux";
import { authReducer } from "./auth/rootReducer";
import { tasksReducer, milestonesReducer } from "./tasks/rootReducer";
import { notificationsReducer } from "./notifications/rootReducer";

export default combineReducers({
  auth: authReducer,
  tasks: tasksReducer,
  milestones: milestonesReducer,
  notifications: notificationsReducer,
});
