import { IonCardTitle, IonBadge, IonCardSubtitle, IonIcon } from "@ionic/react";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import {
  analytics,
  bookmark,
  bookmarks,
  cash,
  code,
  copy,
  discOutline,
  globe,
  happy,
  language,
  micCircle,
  newspaper,
  personCircleOutline,
  stopwatch,
} from "ionicons/icons";
import React from "react";
import { TaskRate } from "..";
import { LANGUAGES, TASK_TYPE } from "../../../store/constants";
import { Task } from "../../../store/types";
import { Flex, Spacer } from "../../atoms";
import { ExportToPdf, TaskActions } from "../../organisms";

type Props = {
  task: Task;
  condensed?: boolean;
};

const useStyle = makeStyles({
  label: {
    display: "flex",
    alignItems: "center",
    fontSize: 15,
    lineHeight: "20px",
    whiteSpace: "pre-wrap",
    "& b": {
      marginRight: 5,
    },
    "& ion-icon": {
      fontSize: 18,
      marginRight: 10,
    },
  },
});

const TaskHeader: React.FC<Props> = ({ task, condensed }) => {
  const style = useStyle();
  const taskType = TASK_TYPE.find((type) => type.id === task.type);
  const taskLang = LANGUAGES.find((lang) => lang.id === task.language);
  const taskBudget =
    task.budget && task.currency ? `${task.budget} ${task.currency}` : "N/A";
  const taskDueDate = task.due_date
    ? dayjs(parseInt(task.due_date) * 1000).format("DD/MM/YYYY")
    : "N/A";

  return (
    <>
      <IonCardTitle>
        <Flex justifyContent="space-between">
          <div>
            {task.title} <IonBadge color="tertiary">{taskType?.name}</IonBadge>{" "}
          </div>
          <TaskActions task_id={task.id} />
        </Flex>
      </IonCardTitle>
      <IonCardSubtitle>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} className={style.label}>
                <IonIcon icon={language} /> <b>Language: </b>
                {taskLang?.name}
              </Grid>
              <Grid item xs={12} md={6} className={style.label}>
                <IonIcon icon={cash} /> <b>Budget: </b>
                {taskBudget}
              </Grid>
              <Grid item xs={12} md={6} className={style.label}>
                <IonIcon icon={stopwatch} /> <b>Due date: </b>
                {taskDueDate}
              </Grid>
              <Grid item xs={12} md={6} className={style.label}>
                <IonIcon icon={personCircleOutline} /> <b>Assigned by: </b>
                {task?.assigned_by_label || task?.created_by}
              </Grid>
              {task.status === "APPROVED" && (
                <Grid item xs={12}>
                  <TaskRate task={task} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!condensed && (
          <>
            <Spacer height="20px" />
            <Divider />
            <Spacer height="20px" />
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={globe} /> <b>Type of website: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.website_type}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={language} /> <b>Keywords: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.keywords}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={analytics} /> <b>Response: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.response}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={globe} /> <b>Similar sites: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.similar_sites}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={code} /> <b>Site tagline: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.site_tagline}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={newspaper} /> <b>Site title: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.site_title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={happy} /> <b>Target audience: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.target_audience}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={micCircle} /> <b>Tone of voice: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.tone_of_voice}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={discOutline} /> <b>Writing perspective: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.writing_perspective}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={copy} /> <b>Num. pages: </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.num_pages}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={bookmark} />{" "}
                <b>
                  Min. {task?.language === "JP" ? "characters" : "words"} per
                  page:{" "}
                </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.min_words_per_page}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={style.label}>
                <IonIcon icon={bookmarks} />{" "}
                <b>
                  Min. {task?.language === "JP" ? "characters" : "words"} total:{" "}
                </b>
                <Typography style={{ wordBreak: "break-word" }}>
                  {task?.min_words_total}
                </Typography>
              </Grid>
              {task.status === "APPROVED" && (
                <Grid item xs={12}>
                  <TaskRate task={task} />
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={12} className={style.label}>
                <p>{task.content_brief}</p>
              </Grid>
            </Grid>
            <ExportToPdf record={task} />
          </>
        )}
      </IonCardSubtitle>
    </>
  );
};

TaskHeader.displayName = "TaskHeader";

export default React.memo(TaskHeader);
