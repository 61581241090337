import React, { useCallback } from "react";
import { Flex } from "../../atoms";
import { IonCard, IonButton } from "@ionic/react";
import { Task } from "../../../store/types";
import { useHistory } from "react-router";
import TaskHeader from "../TaskHeader";

type Props = {
  task: Task;
};

const TaskCard: React.FC<Props> = ({ task }) => {
  const history = useHistory();
  const goToTask = useCallback(() => history.push(`/task/${task.id}`), [
    history,
    task.id,
  ]);

  return (
    <div>
      <Flex alignItems="start" justifyContent="start">
        <Flex
          flexDirection="column"
          alignItems="stretch"
          justifyContent="start"
        >
          <IonCard className="ion-padding">
            <TaskHeader task={task} condensed />
            <Flex justifyContent="flex-end" height="auto">
              <IonButton fill="outline" color="secondary" onClick={goToTask}>
                View
              </IonButton>
            </Flex>
          </IonCard>
        </Flex>
      </Flex>
    </div>
  );
};

TaskCard.displayName = "TaskCard";

export default React.memo(TaskCard);
