import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  updateNotificationRead,
  updateAllNotificationsRead,
} from "../../store/actions";
import {
  getNotificationsList,
  getNotificationsLoading,
  getNotificationsLoaded,
  getNotificationsErrors,
} from "../../store/selectors/notifications";

export const useNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationsList);
  const loading = useSelector(getNotificationsLoading);
  const loaded = useSelector(getNotificationsLoaded);
  const errors = useSelector(getNotificationsErrors);

  const fetchNotifications = useCallback(() => dispatch(getNotifications()), [
    dispatch,
  ]);

  const updateNotificationStatus = useCallback(
    (params) => dispatch(updateNotificationRead(params)),
    [dispatch]
  );

  const markAllNotificationsRead = useCallback(
    () => dispatch(updateAllNotificationsRead()),
    [dispatch]
  );

  return {
    markAllNotificationsRead,
    updateNotificationStatus,
    fetchNotifications,
    notifications,
    loading,
    loaded,
    errors,
  };
};
