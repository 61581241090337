import { fetch } from "../../utils/fetch";
import { NMS_URL } from "../../constants";
import {
  UpdateNotificationReadSuccessResponse,
  UpdateNotificationReadFailureResponse,
  UpdateNotificationReadPayload,
} from "../../types";

type Response =
  | UpdateNotificationReadSuccessResponse
  | UpdateNotificationReadFailureResponse;

export const updateNotificationReadApi = async (
  payload: UpdateNotificationReadPayload
): Promise<Response> => {
  try {
    const response: Response = await fetch.post({
      url: `${NMS_URL}notifications/${payload.notification_id}/mark-read`,
      params: JSON.stringify({ read: payload.read }),
    });
    return response;
  } catch (e) {
    return e;
  }
};
