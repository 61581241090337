import React from "react";
import styled from "styled-components";

type Props = {};

const TabBar = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  z-index: 100;
  background: var(--ion-color-light);
`;

TabBar.displayName = "TabBar";

export default React.memo(TabBar);
