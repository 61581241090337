import { fetch } from "../../utils/fetch";
import { AUTH_URL } from "../../constants";
import {
  SignUpPayload,
  SignUpSuccessResponse,
  SignUpFailureResponse,
} from "../../types";

type Response = SignUpSuccessResponse | SignUpFailureResponse;

export const signUpApi = async (payload: SignUpPayload): Promise<Response> => {
  try {
    const response: Response = await fetch.post({
      url: `${AUTH_URL}users`,
      params: JSON.stringify(payload),
    });
    return response;
  } catch (e) {
    return e;
  }
};
