import { FetchParams } from "../../types";
import { fetchConfigurationHeader, fetchAuthHeader } from "./config";

export async function raw<T>({
  url,
  method = "GET",
  params,
  headers = {},
  json = true,
  formData = false,
  skipAuth = false,
}: FetchParams): Promise<any> {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        ...(!skipAuth && (fetchAuthHeader() || {})),
        ...(!formData ? fetchConfigurationHeader() : {}),
        ...headers,
      },
      body: params ? params : undefined,
    });
    if (response.status >= 400) {
      const json = await response.json();
      return {
        status: response.status,
        errors:
          response.statusText || json.data || json.message || "Generic error",
      };
    }
    return json ? response.json() : response;
  } catch (e) {
    return { status: "KO", errors: e };
  }
}
