import { createReducer } from "typesafe-actions";
import {
  TasksState,
  TasksActions,
  MilestonesState,
  MilestonesActions,
} from "../../types";
import {
  getTasksRequest,
  getTasksFailure,
  getTasksSuccess,
  getMilestonesRequest,
  getMilestonesFailure,
  getMilestonesSuccess,
  updateTaskRequest,
  updateTaskFailure,
  updateTaskSuccess,
  getTaskRequest,
  getTaskFailure,
  getTaskSuccess,
  getMilestoneRequest,
  getMilestoneFailure,
  getMilestoneSuccess,
  updateMilestoneRequest,
  updateMilestoneSuccess,
  updateMilestoneFailure,
  pluckTaskFailure,
  pluckTaskRequest,
  pluckTaskSuccess,
  rejectTaskSuccess,
  rejectTaskFailure,
  rejectTaskRequest,
} from "../../actions";
import {
  getTasksRequestCase,
  getTasksFailureCase,
  getTasksSuccessCase,
} from "./getTasks";
import {
  getMilestonesRequestCase,
  getMilestonesFailureCase,
  getMilestonesSuccessCase,
} from "./getMilestones";
import {
  updateTaskRequestCase,
  updateTaskSuccessCase,
  updateTaskFailureCase,
} from "./updateTask";
import {
  getTaskRequestCase,
  getTaskFailureCase,
  getTaskSuccessCase,
} from "./getTask";
import {
  getMilestoneRequestCase,
  getMilestoneFailureCase,
  getMilestoneSuccessCase,
} from "./getMilestone";
import {
  updateMilestoneRequestCase,
  updateMilestoneSuccessCase,
  updateMilestoneFailureCase,
} from "./updateMilestone";
import {
  pluckTaskRequestCase,
  pluckTaskFailureCase,
  pluckTaskSuccessCase,
} from "./pluckTask";
import {
  rejectTaskRequestCase,
  rejectTaskFailureCase,
  rejectTaskSuccessCase,
} from "./rejectTask";

const initialTasksState = {
  tasks: {
    loading: false,
    loaded: false,
    data: [],
    errors: null,
  },
};

const initialMilestonesState = {
  milestones: {
    loading: false,
    loaded: false,
    data: [],
    errors: null,
  },
};

export const tasksReducer = createReducer<TasksState, TasksActions>(
  initialTasksState
)
  .handleAction(getTasksRequest, getTasksRequestCase)
  .handleAction(getTasksFailure, getTasksFailureCase)
  .handleAction(getTasksSuccess, getTasksSuccessCase)
  .handleAction(getTaskRequest, getTaskRequestCase)
  .handleAction(getTaskFailure, getTaskFailureCase)
  .handleAction(getTaskSuccess, getTaskSuccessCase)
  .handleAction(updateTaskRequest, updateTaskRequestCase)
  .handleAction(updateTaskFailure, updateTaskFailureCase)
  .handleAction(updateTaskSuccess, updateTaskSuccessCase)
  .handleAction(pluckTaskRequest, pluckTaskRequestCase)
  .handleAction(pluckTaskFailure, pluckTaskFailureCase)
  .handleAction(pluckTaskSuccess, pluckTaskSuccessCase)
  .handleAction(rejectTaskRequest, rejectTaskRequestCase)
  .handleAction(rejectTaskFailure, rejectTaskFailureCase)
  .handleAction(rejectTaskSuccess, rejectTaskSuccessCase);

export const milestonesReducer = createReducer<
  MilestonesState,
  MilestonesActions
>(initialMilestonesState)
  .handleAction(getMilestonesRequest, getMilestonesRequestCase)
  .handleAction(getMilestonesFailure, getMilestonesFailureCase)
  .handleAction(getMilestonesSuccess, getMilestonesSuccessCase)
  .handleAction(getMilestoneRequest, getMilestoneRequestCase)
  .handleAction(getMilestoneFailure, getMilestoneFailureCase)
  .handleAction(getMilestoneSuccess, getMilestoneSuccessCase)
  .handleAction(updateMilestoneRequest, updateMilestoneRequestCase)
  .handleAction(updateMilestoneSuccess, updateMilestoneSuccessCase)
  .handleAction(updateMilestoneFailure, updateMilestoneFailureCase);
