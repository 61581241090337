import { createSelector } from 'reselect';
import { AppStore, AuthState } from "../../types";

function getAuthState(state: AppStore): AuthState {
  return state.auth;
}

export const getSignInIsLoading = createSelector(
  getAuthState,
  (authState): AuthState['loading'] => authState.loading,
);

export const getUserInfo = createSelector(
  getAuthState,
  (authState): AuthState['user'] => authState.user
)

export const getIsRecoverEmailSent = createSelector(
  getAuthState,
  (authState): AuthState['recoverPasswordEmailSent'] => !!authState.recoverPasswordEmailSent
)

export const getIsResetPasswordSuccess = createSelector(
  getAuthState,
  (authState): AuthState['resetPasswordSuccess'] => !!authState.resetPasswordSuccess
)

export const getAuthErrors = createSelector(
  getAuthState,
  (authState): AuthState['errors'] => authState.errors
)