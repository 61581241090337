import { SagaIterator } from "redux-saga";
import { ActionType, getType } from "typesafe-actions";
import { call, put, take, race } from "redux-saga/effects";
import {
  signIn,
  signInFailure,
  signInRequest,
  getUserInfo,
  getUserInfoFailure,
  getUserInfoSuccess,
} from "../../actions";
import { signInApi } from "../../api";
import { setAuthToken, setExpirationDate } from "../../utils/storage";

export function* signInSaga(action: ActionType<typeof signIn>): SagaIterator {
  yield put(signInRequest());
  try {
    const params = action.payload;
    const { errors, status, access_token, expires_in } = yield call(
      signInApi,
      params
    );
    if (errors) {
      yield put(signInFailure({ errors, status }));
      return;
    }
    yield call(setAuthToken, access_token);
    yield call(
      setExpirationDate,
      (new Date().getTime() + expires_in * 1000).toString()
    );

    yield put(getUserInfo());

    yield race({
      success: take(getType(getUserInfoFailure)),
      failure: take(getType(getUserInfoSuccess)),
    });
    // yield put(signInSuccess({ access_token, expires_in, token_type }));
  } catch (e) {
    yield put(signInFailure({ errors: e || "Generic error" }));
  }
}
