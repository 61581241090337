import React, { useCallback, useContext } from "react";
import { IonToggle } from "@ionic/react";
import { ThemeMode } from "../../../Theme";

type Props = {};

const ThemeToggle: React.FC<Props> = () => {
  const { prefersDark, setPrefersDark } = useContext(ThemeMode);

  const onToggle = useCallback(
    (ev) => {
      setPrefersDark(ev.detail.checked ? "dark" : "light");
      localStorage.setItem("theme", ev.detail.checked ? "dark" : "light");
    },
    [setPrefersDark]
  );

  return (
    <IonToggle
      checked={prefersDark === "dark"}
      onIonChange={onToggle}
      id="themeToggle"
      slot="end"
      mode="ios"
    ></IonToggle>
  );
};

ThemeToggle.displayName = "ThemeToggle";

export default React.memo(ThemeToggle);
