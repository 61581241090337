import React from "react";
import { IonLabel, IonBadge, IonCardTitle } from "@ionic/react";
import { Milestone as TMilestone } from "../../../store/types";
import { Divider } from "@material-ui/core";
import { Spacer } from "../../atoms";

type Props = {
  milestone?: TMilestone;
};

const Milestone: React.FC<Props> = ({ milestone }) => {
  if (!milestone) {
    return null;
  }
  const statusColor = {
    Pending: "medium",
    Completed: "primary",
    Approved: "success",
    Rejected: "danger",
  };
  return (
    <IonLabel>
      <IonCardTitle style={{ fontSize: 18 }}>
        <b>Page title:</b> {milestone.title}{" "}
        <IonBadge color={statusColor[milestone.status] || "medium"}>
          {milestone.status}
        </IonBadge>
      </IonCardTitle>
      <Spacer height="10px" />
      <Divider />
      {milestone.description && (
        <>
          <Spacer height="10px" />
          <p>Topics that need to be covered:</p>
          <ul>
            {milestone?.description?.split("\n").map((p, i) => (
              <li key={`p-${i}`}>{p}</li>
            ))}
          </ul>
        </>
      )}
    </IonLabel>
  );
};

Milestone.displayName = "Milestone";

export default React.memo(Milestone);
