import { createAction } from "typesafe-actions";
import {
  GetMilestonesPayload,
  GetMilestonesSuccessResponse,
  GetMilestonesFailureResponse,
} from "../../types";

export const getMilestones = createAction("trigger/GET_MILESTONES")<
  GetMilestonesPayload
>();
export const getMilestonesRequest = createAction(
  "action/GET_MILESTONES_REQUEST"
)();
export const getMilestonesSuccess = createAction(
  "action/GET_MILESTONES_SUCCESS"
)<GetMilestonesSuccessResponse["data"]>();
export const getMilestonesFailure = createAction(
  "action/GET_MILESTONES_FAILURE"
)<GetMilestonesFailureResponse>();
