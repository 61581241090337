import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateMilestonePayload } from "../../store/types";
import {
  getMilestonesList,
  getMilestonesLoaded,
  getTasksList,
  getTasksLoaded,
  getMilestonesLoading,
  getTasksLoading,
  getMilestonesErrors,
  getMilestonesSuccess,
} from "../../store/selectors";
import {
  getMilestone,
  updateMilestone as updateMilestoneAction,
  getTask,
} from "../../store/actions";

export const useMilestone = ({ milestone_id }: { milestone_id: string }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const [fetchedTasks, setFetchedTasks] = useState(false);
  const tasks = useSelector(getTasksList);
  const loaded = useSelector(getMilestonesLoaded);
  const loading = useSelector(getMilestonesLoading);
  const success = useSelector(getMilestonesSuccess);
  const milestones = useSelector(getMilestonesList);
  const error = useSelector(getMilestonesErrors);
  const tasksLoaded = useSelector(getTasksLoaded);
  const tasksLoading = useSelector(getTasksLoading);
  const milestone = milestones.find(
    (milestone) => milestone.id === milestone_id
  );
  const task = useMemo(
    () => tasks.find((task) => task.id === milestone?.project_id || null),
    [tasks, milestone]
  );
  const fetchMilestone = useCallback(
    () => dispatch(getMilestone({ milestone_id })),
    [dispatch, milestone_id]
  );
  const updateMilestone = useCallback(
    (params: UpdateMilestonePayload) => dispatch(updateMilestoneAction(params)),
    [dispatch]
  );
  const fetchTask = useCallback(
    () => dispatch(getTask({ task_id: milestone?.project_id || "" })),
    [dispatch, milestone]
  );
  const taskImages = useMemo(() => {
    if (!task || !task?.attachments) {
      return undefined;
    }
    return task.attachments.map((att) => {
      return {
        title: att.url.split("/").pop(),
        value: att.url,
      };
    });
  }, [task]);

  useEffect(() => {
    if (
      milestone?.project_id &&
      ((!tasksLoaded && !tasksLoading) ||
        (tasksLoaded && !task && !fetchedTasks))
    ) {
      fetchTask();
      setFetchedTasks(true);
    }
  }, [milestone, fetchTask, task, fetchedTasks, tasksLoaded, tasksLoading]);

  useEffect(() => {
    if ((!loaded && !loading) || (loaded && !milestone && !fetched)) {
      fetchMilestone();
      setFetched(true);
    }
  }, [loaded, loading, fetchMilestone, fetched, milestone]);

  return {
    task,
    error,
    loaded,
    loading,
    success,
    milestone,
    taskImages,
    updateMilestone,
  };
};
