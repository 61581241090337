import {
  TasksState,
  GetTasksSuccessAction,
  GetTasksFailureAction,
  GetTasksRequestAction,
} from "../../types";

export const getTasksRequestCase = (
  state: TasksState,
  _: GetTasksRequestAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    loading: true,
  },
});

export const getTasksSuccessCase = (
  state: TasksState,
  action: GetTasksSuccessAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    data: action.payload,
    loading: false,
    loaded: true,
  },
});

export const getTasksFailureCase = (
  state: TasksState,
  action: GetTasksFailureAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    errors: action.payload.errors,
    loading: false,
    loaded: true,
  },
});
