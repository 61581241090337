import { createAction } from "typesafe-actions";
import {
  UpdateTaskPayload,
  UpdateTaskFailureResponse,
  UpdateTaskSuccessResponse,
} from "../../types";

export const updateTask = createAction("trigger/UPDATE_TASK")<
  UpdateTaskPayload
>();
export const updateTaskRequest = createAction("action/UPDATE_TASK_REQUEST")();
export const updateTaskSuccess = createAction("action/UPDATE_TASK_SUCCESS")<
  UpdateTaskSuccessResponse['data']
>();
export const updateTaskFailure = createAction("action/UPDATE_TASK_FAILURE")<
  UpdateTaskFailureResponse
>();
