import {
  AuthState,
  UpdateProfileSuccessAction,
  UpdateProfileFailureAction,
  UpdateProfileRequestAction,
} from "../../types";

export const updateProfileRequestCase = (
  state: AuthState,
  _: UpdateProfileRequestAction
): AuthState => ({
  ...state,
  loading: true,
});

export const updateProfileSuccessCase = (
  state: AuthState,
  action: UpdateProfileSuccessAction
): AuthState => ({
  ...state,
  user: action.payload.data,
  loading: false,
});

export const updateProfileFailureCase = (
  state: AuthState,
  action: UpdateProfileFailureAction
): AuthState => ({
  ...state,
  loading: false,
  errors: action.payload.errors,
});
