import React from "react";
import { Avatar, Typography, useTheme } from "@material-ui/core";
import { IonContent, IonPage } from "@ionic/react";
import { useAuthentication } from "../../hooks";
import { Flex } from "../../components/atoms";
import { DashboardHeader } from "../../components/molecule";
import { EditAccountForm } from "../../components/organisms";

const MyAccountScreen: React.FC = () => {
  const { user } = useAuthentication();
  const theme = useTheme();
  return (
    <IonPage>
      <DashboardHeader />
      <IonContent className="ion-padding">
        <Flex flexDirection="column">
          <Avatar
            alt={`${user?.name} ${user?.last_name}`}
            style={{
              width: 100,
              height: 100,
              backgroundColor: theme.palette.secondary.main,
            }}
            color="primary"
          >
            <Typography variant="h3">
              <b>
                {user?.name[0]}
                {user?.last_name && user?.last_name[0]}
              </b>
            </Typography>
          </Avatar>
          <Typography variant="h5" color="textPrimary">
            My Account
          </Typography>
          {user && <EditAccountForm user={user} />}
        </Flex>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(MyAccountScreen);
