import React, { useState, useCallback, useEffect } from "react";
import { IonButton, IonIcon, IonSpinner, IonBadge } from "@ionic/react";
import { notificationsOutline } from "ionicons/icons";
import { NotificationsList } from "../../organisms";
import { useNotifications } from "../../../hooks/notifications/useNotifications";
import { Notification } from "../../../store/types";
import { Drawer } from "@material-ui/core";

type Props = {};

const NotificationsButton: React.FC<Props> = () => {
  const [notificationsOpen, setNotificationsOpen] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  const {
    fetchNotifications,
    notifications,
    loaded,
    loading,
    errors,
  } = useNotifications();
  const [openedFor, setOpenedFor] = useState<Notification[]>([]);
  const [isIdle, setIsIdle] = useState(false);
  const [intervalId, setIntervalId] = useState<number>();

  const unreadNotifications = notifications.filter(
    (notification) => !notification.read
  );

  useEffect(() => {
    let timeout: any;
    setIsIdle(false);
    const resetTimeout = () => {
      clearTimeout(timeout);
      setIsIdle(false);
      timeout = setTimeout(() => setIsIdle(true), 60000);
    };
    window.onmousemove = resetTimeout;
    window.onkeypress = resetTimeout;
  }, []);

  useEffect(() => {
    const interval = process.env.NODE_ENV === "production" ? 5000 : 15000;
    fetchNotifications();
    if (isIdle) {
      clearInterval(intervalId);
    } else {
      setIntervalId(() =>
        setInterval(() => {
          fetchNotifications();
        }, interval)
      );
    }
    // eslint-disable-next-line
  }, [isIdle, fetchNotifications]);

  useEffect(() => {
    const newUnreads = !unreadNotifications.every((notification) =>
      openedFor.find(
        (readNotification) => readNotification.id === notification.id
      )
    );
    if (unreadNotifications.length && newUnreads) {
      document.getElementById("notifications-button")?.click();
      setOpenedFor(unreadNotifications);
    }
  }, [unreadNotifications, openedFor]);

  const togglePopover = useCallback(
    (event?: any) => {
      setNotificationsOpen({
        open: !notificationsOpen.open,
        event: event.nativeEvent,
      });
    },
    [notificationsOpen]
  );

  return (
    <>
      <IonButton
        slot="primary"
        fill="outline"
        onClick={togglePopover}
        disabled={!!errors}
        className="buttonWithBadge"
        id="notifications-button"
      >
        {!loaded && loading ? (
          <IonSpinner name="bubbles" />
        ) : (
            <IonIcon icon={notificationsOutline} />
          )}
        <IonBadge
          color="danger"
          className="buttonBadge"
          hidden={!errors && !unreadNotifications.length}
        >
          {errors ? "!" : unreadNotifications.length}
        </IonBadge>
      </IonButton>
      <Drawer
        open={notificationsOpen.open}
        onClose={togglePopover}
        anchor="right"
      >
        <NotificationsList onClose={togglePopover} />
      </Drawer>
    </>
  );
};

NotificationsButton.displayName = "NotificationsButton";

export default React.memo(NotificationsButton);
