import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { IonPage, IonContent, IonCard } from "@ionic/react";
import { DashboardHeader, Milestone, Editor } from "../../components/molecule";
import { Flex, Spacer } from "../../components/atoms";
import { useMilestone } from "../../hooks";
import { MilestoneActions } from "../../components/organisms";

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const MilestoneScreen: React.FC<Props> = ({ match }) => {
  const history = useHistory();
  const [content, setContent] = useState<string>();
  const [error, setError] = useState<string | null>("No changes to submit");
  const { milestone_id } = match.params;
  const { task, loaded, loading, milestone, taskImages } = useMilestone({
    milestone_id,
  });

  const handleContentChange = useCallback(
    (content, editor) => {
      const charsCount = (
        editor.getBody().innerText || editor.getBody().textContent
      ).trim().length;
      const wordCount = editor.plugins.wordcount.body.getWordCount();
      const minimumReached =
        task?.language === "JP"
          ? charsCount >= (Number(task?.min_words_per_page) ?? 0)
          : wordCount >= (Number(task?.min_words_per_page) ?? 0);

      if (!minimumReached) {
        setError(
          `The content should have minimum ${task?.min_words_per_page} words`
        );
      } else {
        setError(null);
      }
      setContent(content);
    },
    [task]
  );

  useEffect(() => {
    if (loaded && !loading && !milestone) {
      history.push("/dashboard");
    }
  }, [loaded, history, milestone, loading]);

  useEffect(() => {
    if (milestone?.content) {
      setContent(milestone?.content);
    }
  }, [milestone]);

  return (
    <IonPage>
      <DashboardHeader />
      <IonContent className="ion-padding">
        <IonCard className="ion-padding">
          <Milestone milestone={milestone} />
        </IonCard>
        <IonCard className="ion-padding">
          {task && task.id && milestone && milestone.id && (
            <Editor
              disabled={milestone.status === "Approved"}
              initialValue={milestone?.content}
              value={content}
              onEditorChange={handleContentChange}
              image_list={taskImages}
              project_id={task.id}
            />
          )}
          <Spacer height="20px" />
          {milestone?.status !== "Approved" && (
            <Flex justifyContent="flex-end">
              <MilestoneActions
                milestone_id={milestone_id}
                data={{ content, status: milestone?.status }}
                disableSubmission={error}
              />
            </Flex>
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

MilestoneScreen.displayName = "MilestoneScreen";

export default React.memo(MilestoneScreen);
