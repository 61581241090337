import {
  PluckTaskSuccessAction,
  PluckTaskFailureAction,
  PluckTaskRequestAction,
  TasksState,
} from "../../types";

export const pluckTaskRequestCase = (
  state: TasksState,
  _: PluckTaskRequestAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
  },
});

export const pluckTaskSuccessCase = (
  state: TasksState,
  action: PluckTaskSuccessAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    data: action.payload,
  },
});

export const pluckTaskFailureCase = (
  state: TasksState,
  action: PluckTaskFailureAction
): TasksState => ({
  ...state,
  tasks: {
    ...state.tasks,
    errors: action.payload.errors,
  },
});
