import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useAuthentication } from "../../../hooks";
import { Spacer, FormErrors, FormSubmit } from "../../atoms";
import { FormInput, ErrorToast } from "../../molecule";
import { LANGUAGES, PAYMENT_PREFERENCES } from "../../../store/constants";
import { Address, User } from "../../../store/types";

type Props = {
  user: User;
};

type ExtendedUser = User &
  Address & {
    languages: any;
  };

const EditAccountForm: React.FC<Props> = ({ user }) => {
  const { loading, errors: authErrors, updateProfile } = useAuthentication();
  const { handleSubmit, errors, control } = useForm<ExtendedUser>({
    defaultValues:
      {
        ...user,
        ...user.addresses[0],
        languages: user.tags.languages,
      } || undefined,
  });

  const onSubmit = useCallback(
    (values: any) => {
      const {
        languages,
        street,
        city,
        country,
        state,
        note,
        postal_code,
        ...payload
      } = values;
      updateProfile({
        id: user.id,
        ...payload,
        tags: { languages },
        addresses: [
          {
            id: user.addresses[0]?.id ?? undefined,
            street,
            city,
            state,
            note,
            is_primary: true,
            is_billing: true,
            is_shipping: true,
          },
        ],
      });
    },
    [user, updateProfile]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Spacer height="20px" />
      <FormInput name="paypal_email" type="email" control={control} required />
      <FormInput name="email" type="email" control={control} required />
      <FormInput name="name" label="First name" control={control} required />
      <FormInput name="last_name" control={control} required />
      <FormInput
        name="languages"
        control={control}
        options={LANGUAGES}
        multiple
        required
      />
      <FormInput name="street" control={control} />
      <FormInput name="country" control={control} />
      <FormInput name="city" control={control} />
      <FormInput name="state" control={control} />
      <FormInput name="note" control={control} />
      <FormInput name="postal_code" control={control} />
      <FormInput
        options={PAYMENT_PREFERENCES}
        name="payment_preference"
        control={control}
        required
      />
      {errors && <FormErrors errors={Object.values(errors)} />}
      <Spacer height="20px" />
      <FormSubmit label="Update profile" loading={loading} />
      <ErrorToast message={authErrors} />
    </form>
  );
};

EditAccountForm.displayName = "EditAccountForm";

export default React.memo(EditAccountForm);
