import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { userInfoApi } from "../../api";
import {
  getUserInfoRequest,
  getUserInfoFailure,
  getUserInfoSuccess,
} from "../../actions";

export function* getUserInfoSaga(): SagaIterator {
  yield put(getUserInfoRequest());
  try {
    const { errors, status, ...payload } = yield call(userInfoApi);
    if (errors || !payload.data) {
      yield put(getUserInfoFailure({ errors, status }));
      return;
    }
    yield put(getUserInfoSuccess(payload.data));
  } catch (e) {
    yield put(getUserInfoFailure({ errors: e || "Generic error" }));
  }
}
