import {
  AuthState,
  GetUserInfoRequestAction,
  GetUserInfoFailureAction,
  GetUserInfoSuccessAction,
} from "../../types";

export const getUserInfoRequestCase = (
  state: AuthState,
  _: GetUserInfoRequestAction
): AuthState => ({
  ...state,
  loading: true,
});

export const getUserInfoSuccessCase = (
  state: AuthState,
  action: GetUserInfoSuccessAction
): AuthState => ({
  ...state,
  user: action.payload,
  loading: false,
});

export const getUserInfoFailureCase = (
  state: AuthState,
  action: GetUserInfoFailureAction
): AuthState => ({
  ...state,
  loading: false,
  errors: action.payload.errors,
});
