import React, { useState, useCallback } from "react";
import { IonButton, IonIcon, IonPopover, IonActionSheet } from "@ionic/react";
import { menu } from "ionicons/icons";
import { useMediaQuery, useMenuList } from "../../../hooks";
import { MainMenu } from "../../organisms";

type Props = {};

const MenuButton: React.FC<Props> = () => {
  const actions = useMenuList();
  const { isMobile } = useMediaQuery();
  const [menuOpen, setMenuOpen] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  const togglePopover = useCallback(
    (event?: any) => {
      setMenuOpen({
        open: !menuOpen.open,
        event: event.nativeEvent,
      });
    },
    [menuOpen]
  );

  return (
    <>
      <IonButton slot="start" fill="outline" onClick={togglePopover}>
        <IonIcon icon={menu} />
      </IonButton>
      {!isMobile && (
        <IonPopover
          isOpen={menuOpen.open}
          event={menuOpen.event}
          onDidDismiss={togglePopover}
        >
          <MainMenu />
        </IonPopover>
      )}
      {isMobile && (
        <IonActionSheet
          isOpen={menuOpen.open}
          onDidDismiss={togglePopover}
          buttons={actions}
        />
      )}
    </>
  );
};

MenuButton.displayName = "MenuButton";

export default React.memo(MenuButton);
