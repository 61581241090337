import React, { useCallback, useEffect, useState } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { helpCircleOutline } from "ionicons/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import video from "../../../assets/mangostudio.mp4";

type Props = {};

const HelpButton: React.FC<Props> = () => {
  const [open, setOpen] = useState(false);
  const toggleDialog = useCallback(() => setOpen((o) => !o), []);
  useEffect(() => {
    const seen = localStorage.getItem("video");
    if (seen !== "true") {
      setOpen(true);
    }
  }, []);

  const showAgain = useCallback(() => {
    localStorage.removeItem("video");
    setOpen(false);
  }, []);

  const dontShowAgain = useCallback(() => {
    localStorage.setItem("video", "true");
    setOpen(false);
  }, []);

  return (
    <>
      <IonButton
        slot="primary"
        fill="outline"
        onClick={toggleDialog}
        className="buttonWithBadge"
      >
        <IonIcon icon={helpCircleOutline} />
      </IonButton>
      <Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>How to...</DialogTitle>
        <DialogContent>
          <video style={{ width: 800, maxWidth: "100%" }} controls>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </DialogContent>
        <DialogActions>
          {localStorage.getItem("video") !== "true" && (
            <Button onClick={dontShowAgain}>Don't show again</Button>
          )}
          {localStorage.getItem("video") === "true" && (
            <Button onClick={showAgain}>Show again next time</Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

HelpButton.displayName = "HelpButton";

export default React.memo(HelpButton);
