import { getAuthToken } from "../storage";

export const fetchAuthHeader = () => {
  let headers = {};
  const token = getAuthToken();
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      AuthType: "default",
    };
  }
  return {
    ...headers,
  };
};
export const fetchConfigurationHeader = () => ({
  "Content-Type": "application/json",
});
