import {
  NotificationsState,
  GetNotificationsSuccessAction,
  GetNotificationsFailureAction,
  GetNotificationsRequestAction,
} from "../../types";

export const getNotificationsRequestCase = (
  state: NotificationsState,
  _: GetNotificationsRequestAction
): NotificationsState => ({
  ...state,
  loading: true,
});

export const getNotificationsSuccessCase = (
  state: NotificationsState,
  action: GetNotificationsSuccessAction
): NotificationsState => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

export const getNotificationsFailureCase = (
  state: NotificationsState,
  action: GetNotificationsFailureAction
): NotificationsState => ({
  ...state,
  errors: action.payload.errors,
  loading: false,
  loaded: true,
});
