import { fetch } from "../../utils/fetch";
import { NMS_URL } from "../../constants";
import {
  GetNotificationsSuccessResponse,
  GetNotificationsFailureResponse,
} from "../../types";

type Response =
  | GetNotificationsFailureResponse
  | GetNotificationsSuccessResponse;

export const getNotificationsApi = async (): Promise<Response> => {
  try {
    const response: Response = await fetch.get({
      url: `${NMS_URL}notifications/me`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
