import React from "react";
import {
  IonPage,
  IonContent,
  IonRow,
  IonCol,
  IonGrid,
  IonCard,
} from "@ionic/react";
import { ManguitoJudgement, ManguitoChart } from "../../components/organisms";
import TasksOverview from "../../components/organisms/TasksOverview";
import { DashboardHeader } from "../../components/molecule";

const DashboardScreen: React.FC = () => {
  return (
    <IonPage>
      <DashboardHeader />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size-xl="5" size-md="5" size-xs="12">
              <IonCard
                className="ion-padding"
                style={{ height: "100%", marginTop: 0, marginBottom: 0 }}
              >
                <ManguitoJudgement />
              </IonCard>
            </IonCol>
            <IonCol size-xl="7" size-md="7" size-xs="12">
              <IonCard
                className="ion-padding"
                style={{ height: "100%", marginTop: 0, marginBottom: 0 }}
              >
                <ManguitoChart />
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TasksOverview />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

DashboardScreen.displayName = "DashboardScreen";

export default React.memo(DashboardScreen);
