import React from "react";
import { MangoAvatar, Spacer, Flex } from "../../atoms";
import { IonProgressBar, IonSpinner } from "@ionic/react";
import { useTasksRates, useTasksList } from "../../../hooks";

const ManguitoJudgement: React.FC = () => {
  const { loading } = useTasksList();
  const {
    punctualityPercentage,
    contentPercentage,
    generalRate,
    completedTasksCount,
  } = useTasksRates();
  if (loading) {
    return (
      <Flex>
        <IonSpinner name="bubbles" />
      </Flex>
    );
  }
  return (
    <Flex flexDirection="column">
      <h2>Manguito's Judgement</h2>
      <MangoAvatar rate={generalRate} count={completedTasksCount} />
      <h4>Welcome to MangoStudio!</h4>
      <Spacer height="20px" />
      <h6>
        Content <b>{contentPercentage.toFixed(2)}%</b>
      </h6>
      <IonProgressBar
        value={contentPercentage / 100}
        color={contentPercentage < 50 ? "danger" : "success"}
        style={{ height: 10, borderRadius: 10 }}
      />
      <Spacer height="10px" />
      <h6>
        Punctuality <b>{punctualityPercentage.toFixed(2)}%</b>
      </h6>
      <IonProgressBar
        value={punctualityPercentage / 100}
        color={contentPercentage < 50 ? "danger" : "success"}
        style={{ height: 10, borderRadius: 10 }}
      />
    </Flex>
  );
};

ManguitoJudgement.displayName = "ManguitoJudgement";

export default React.memo(ManguitoJudgement);
