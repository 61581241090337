import { Box, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React from "react";
import { Task } from "../../../store/types";
import { Flex } from "../../atoms";

type Props = {
  task: Task;
};

const TaskRate: React.FC<Props> = ({ task }) => {
  const { punctuality_rate = "0", content_rate = "0" } = task;
  return (
    <Flex justifyContent="flex-start">
      <Box padding={1} paddingLeft={0}>
        <Typography variant="caption" component="legend">
          Content
        </Typography>
        <Rating
          precision={0.5}
          size="small"
          value={parseFloat(content_rate)}
          name="punctuality"
          readOnly
        />
      </Box>
      <Box padding={1} paddingLeft={0}>
        <Typography variant="caption" component="legend">
          Punctuality
        </Typography>
        <Rating
          precision={0.5}
          size="small"
          value={parseFloat(punctuality_rate)}
          name="punctuality"
          color="primary"
          readOnly
        />
      </Box>
    </Flex>
  );
};

TaskRate.displayName = "TaskRate";

export default React.memo(TaskRate);
