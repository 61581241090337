import { fetch } from "../../utils/fetch";
import { MMS_URL } from "../../constants";
import {
  PluckTaskSuccessResponse,
  PluckTaskFailureResponse,
  PluckTaskPayload,
} from "../../types";

type Response = PluckTaskSuccessResponse | PluckTaskFailureResponse;

export const pluckTasksApi = async (
  payload: PluckTaskPayload
): Promise<Response> => {
  try {
    const response: Response = await fetch.get({
      url: `${MMS_URL}projects/${payload.project_id}/${payload.unpluck ? "unpluck" : "pluck"
        }`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
