import { useAuthentication } from "../auth";
import {
  settingsOutline,
  logOutOutline,
  speedometerOutline,
  addOutline,
  checkmarkDone,
  construct,
  hourglassOutline,
  personCircleOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";

export const useMenuList = () => {
  const history = useHistory();
  const { logout } = useAuthentication();

  const actions = [
    {
      text: "Dashboard",
      icon: speedometerOutline,
      handler: (): void => {
        history.push("/dashboard");
      },
    },
    {
      text: "Over due tasks",
      icon: hourglassOutline,
      handler: (): void => {
        history.push("/tasks/over_due");
      },
    },
    {
      text: "In progress",
      icon: construct,
      handler: (): void => {
        history.push("/tasks/in_progress");
      },
    },
    {
      text: "New projects",
      icon: addOutline,
      handler: (): void => {
        history.push("/tasks/new_projects");
      },
    },
    {
      text: "Completed",
      icon: checkmarkDone,
      handler: (): void => {
        history.push("/tasks/completed");
      },
    },
    {
      text: "Logout",
      icon: logOutOutline,
      handler: (): void => {
        logout();
      },
    },
    {
      text: "Settings",
      icon: settingsOutline,
      handler: (): void => {
        history.push("/settings");
      },
    },
    {
      text: "My Account",
      icon: personCircleOutline,
      handler: (): void => {
        history.push("/my-account");
      },
    },
  ];

  return actions;
};
