import { fetch } from "../../utils/fetch";
import { AUTH_URL } from "../../constants";
import {
  VerifyTokenResponseSuccess,
  VerifyTokenFailureAction,
} from "../../types";

type Response = VerifyTokenResponseSuccess | VerifyTokenFailureAction;

export const verifyTokenApi = async (): Promise<Response> => {
  try {
    const response: Response = await fetch.get({
      url: `${AUTH_URL}auth/verify-token`,
    });
    return response;
  } catch (e) {
    return e;
  }
};
