import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call, select } from "redux-saga/effects";
import { getTaskApi } from "../../api/tasks/getTask";
import {
  getTask,
  getTaskRequest,
  getTaskSuccess,
  getTaskFailure,
} from "../../actions";
import { getTasksList } from "../../selectors";
import { Task } from "../../types";

export function* getTaskSaga(action: ActionType<typeof getTask>): SagaIterator {
  yield put(getTaskRequest());
  try {
    const params = action.payload;
    const { errors, status, ...payload } = yield call(getTaskApi, params);
    if (errors || !payload.data) {
      console.log(errors, payload.data);
      yield put(getTaskFailure({ errors, status }));
      return;
    }
    const tasks = yield select(getTasksList);
    const newTasks: Task[] = [
      ...tasks.filter((task: Task) => task.id !== payload.data.id),
      payload.data,
    ];

    yield put(getTaskSuccess({ data: newTasks }));
  } catch (e) {
    yield put(getTaskFailure({ errors: e || "Generic error" }));
  }
}
