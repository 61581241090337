import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { put, call } from "redux-saga/effects";
import {
  refreshToken,
  refreshTokenRequest,
  refreshTokenFailure,
  signOut,
  refreshTokenSuccess,
} from "../../actions";
import { refreshTokenApi } from "../../api/auth/refreshToken";
import { setAuthToken, setExpirationDate } from "../../utils/storage";

export function* refreshTokenSaga(
  action: ActionType<typeof refreshToken>
): SagaIterator {
  yield put(refreshTokenRequest());
  try {
    const { errors, access_token, expires_in } = yield call(
      refreshTokenApi,
      action.payload
    );
    if (errors) {
      yield put(refreshTokenFailure());
      yield put(signOut());
      return;
    }
    yield call(setAuthToken, access_token);
    yield call(
      setExpirationDate,
      (new Date().getTime() + expires_in * 1000).toString()
    );
  } catch (e) {
    yield put(refreshTokenFailure());
    yield put(signOut());
    return;
  }
  yield put(refreshTokenSuccess());
}
