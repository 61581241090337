export const TASK_STATUS = [
  { id: "REJECTED", name: "Rejected" },
  { id: "NOT_PLUCKED", name: "Not plucked" },
  { id: "IN_PROGRESS", name: "In progress" },
  { id: "COMPLETED", name: "Completed" },
  { id: "APPROVED", name: "Approved" },
  { id: "ARCHIVED", name: "Archived" },
];
export const TASK_TYPE = [
  { id: "TAG_LINKS", name: "Tag linksites", data_type: "website_ids" },
  {
    id: "UPDATE_LINKS",
    name: "Update directory links",
    data_type: "website_ids",
  },
  { id: "CREATE_POST", name: "Content", data_type: "post_ids" },
  { id: "QUALITY_CHECK", name: "QA", data_type: "task_ids" },
];
