import { SagaIterator } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { call, put, select } from "redux-saga/effects";
import { pluckTasksApi } from "../../api/tasks/pluckTask";
import {
  pluckTask,
  pluckTaskRequest,
  pluckTaskSuccess,
  pluckTaskFailure,
} from "../../actions";
import { getTasksList } from "../../selectors";
import { Task } from "../../types";

export function* pluckTaskSaga(
  action: ActionType<typeof pluckTask>
): SagaIterator {
  yield put(pluckTaskRequest());
  try {
    const params = action.payload;
    const { errors, status, data } = yield call(pluckTasksApi, params);
    if (errors || !data || !data.id) {
      yield put(pluckTaskFailure({ errors, status }));
      return;
    }

    const tasks = yield select(getTasksList);
    const newTasks = tasks.filter((task: Task) => task.id !== data.id);
    yield put(pluckTaskSuccess([...newTasks, data]));
  } catch (e) {
    yield put(pluckTaskFailure({ errors: e || "Generic error" }));
  }
}
