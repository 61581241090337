import { createAction } from "typesafe-actions";
import { GetTasksSuccessResponse, GetTasksFailureResponse } from "../../types";

export const getTasks = createAction("trigger/GET_TASKS")();
export const getTasksRequest = createAction("action/GET_TASKS_REQUEST")();
export const getTasksSuccess = createAction(
  "action/GET_TASKS_SUCCESS"
)<GetTasksSuccessResponse["data"]>();
export const getTasksFailure = createAction(
  "action/GET_TASKS_FAILURE"
)<GetTasksFailureResponse>();
