import { NotificationsState, AppStore } from "../../types";
import { createSelector } from "reselect";

function getNotificationsState(state: AppStore): NotificationsState {
  return state.notifications;
}

export const getNotificationsList = createSelector(
  getNotificationsState,
  (notificationsState): NotificationsState['data'] => notificationsState.data,
);

export const getNotificationsLoading = createSelector(
  getNotificationsState,
  (notificationsState): NotificationsState['loading'] => notificationsState.loading,
);

export const getNotificationsLoaded = createSelector(
  getNotificationsState,
  (notificationsState): NotificationsState['loaded'] => notificationsState.loaded,
);

export const getNotificationsErrors = createSelector(
  getNotificationsState,
  (notificationsState): NotificationsState['errors'] => notificationsState.errors,
);