import {
  NotificationsState,
  UpdateNotificationReadSuccessAction,
  UpdateNotificationReadFailureAction,
  UpdateNotificationReadRequestAction,
} from "../../types";

export const updateNotificationReadRequestCase = (
  state: NotificationsState,
  _: UpdateNotificationReadRequestAction
): NotificationsState => ({
  ...state,
  loading: true,
});

export const updateNotificationReadSuccessCase = (
  state: NotificationsState,
  action: UpdateNotificationReadSuccessAction
): NotificationsState => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

export const updateNotificationReadFailureCase = (
  state: NotificationsState,
  action: UpdateNotificationReadFailureAction
): NotificationsState => ({
  ...state,
  errors: action.payload.errors,
  loading: false,
  loaded: true,
});
