import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";

// #region ::: THEME
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

// #endregion

import { ProtectedRoute } from "./components/organisms";
import { useAuthentication, useThemeMode } from "./hooks";
import {
  SignUpScreen,
  LoginScreen,
  RecoverPasswordScreen,
  ResetPasswordScreen,
  DashboardScreen,
  SettingsScreen,
  TasksScreen,
  TaskScreen,
  MilestoneScreen,
  MyAccountScreen,
} from "./views";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { ThemeMode } from "./Theme";

const App: React.FC = () => {
  const { hasValidToken, user, verifyToken } = useAuthentication();
  const { prefersDark, setPrefersDark } = useThemeMode();

  const darkState = prefersDark === "dark";

  useEffect(() => {
    if (hasValidToken && !user) {
      verifyToken();
    }
  }, [user, hasValidToken, verifyToken]);

  const theme = createMuiTheme({
    palette: {
      type: darkState ? "dark" : "light",
      primary: {
        main: "#fec502",
      },
      secondary: {
        main: "#3bb777",
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <ThemeMode.Provider value={{ prefersDark, setPrefersDark }}>
      <ThemeProvider theme={theme}>
        <IonApp>
          <IonReactHashRouter>
            <IonRouterOutlet>
              <ProtectedRoute
                path="/login"
                authenticated={false}
                component={LoginScreen}
                exact={true}
              />
              <ProtectedRoute
                path="/sign-up"
                authenticated={false}
                component={SignUpScreen}
                exact={true}
              />
              <ProtectedRoute
                path="/recover-password"
                authenticated={false}
                component={RecoverPasswordScreen}
                exact={true}
              />
              <ProtectedRoute
                path="/reset-password"
                authenticated={false}
                component={ResetPasswordScreen}
                exact={true}
              />
              <ProtectedRoute
                path="/dashboard"
                component={DashboardScreen}
                exact={true}
              />
              <ProtectedRoute
                path="/tasks/:tasks_group"
                component={TasksScreen}
              />
              <ProtectedRoute path="/task/:task_id" component={TaskScreen} />
              <ProtectedRoute
                path="/milestone/:milestone_id"
                component={MilestoneScreen}
              />
              <ProtectedRoute
                path="/settings"
                component={SettingsScreen}
                exact={true}
              />
              <ProtectedRoute
                path="/my-account"
                component={MyAccountScreen}
                exact={true}
              />
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
            </IonRouterOutlet>
          </IonReactHashRouter>
        </IonApp>
      </ThemeProvider>
    </ThemeMode.Provider>
  );
};

export default App;
